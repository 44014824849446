<template>
  <Create :product-id="productId">
    <template #default>
      <VpRequest :get="getMeta" ref="request" cache="productAssignedAttributes">
        <template #default>
          <VpVueForm
            class="vp-p-6"
            :id="variantId"
            :fields="fields"
            :get="get"
            :save="save"
            #default="{ state, isCreating, response }"
            width="400px"
            :del="del"
            ref="item"
            :form-state.sync="formState"
            collection-cache="productVariants"
            cache="productVariant"
            feature="PRODUCT_VARIANT"
          >
            <div class="vp-pb-6 vp-border-b">
              <h4 class="vp-text-gray-500 vp-text-lg vp-font-bold">
                Attributes
              </h4>
              <div
                :class="
                  isCreating ? 'vp-space-y-6 vp-mt-4' : 'vp-space-y-3 vp-mt-1'
                "
              >
                <VpLoaderContainer class="vp-p-4" v-if="!attributes" loading />
                <template v-else v-for="(attr, index) in attributes">
                  <VpField
                    v-if="isCreating"
                    rules="required"
                    :label="attr.name"
                    :name="attr.name"
                    :key="`attr-${index}`"
                  >
                    <div>
                      <ProductAttributeInput
                        :ui-element="attr.uiElement"
                        :options="attr.options"
                        :value="
                          state.attributeInput[index] &&
                          state.attributeInput[index].optionId
                        "
                        @input="
                          $set(state.attributeInput, index, {
                            optionId: $event,
                            attributeId: attr.id,
                          })
                        "
                      />

                      <VyButton
                        label="Add New Option"
                        class="vp-mt-2 button--gray"
                        @click.native="addNew(attr.id, attr.uiElement)"
                      />
                    </div>
                  </VpField>

                  <ProductAttribute
                    v-else
                    :key="`attribute-${index}`"
                    :attribute="
                      response.attributes[index] &&
                      response.attributes[index].attribute
                    "
                    :option="
                      response.attributes[index] &&
                      response.attributes[index].option
                    "
                  />
                </template>

                <p
                  v-if="isCreating && selectedVariantExists"
                  class="vp-text-danger-500"
                >
                  The variant with selected attributes already exists. Please
                  select a diffrent combination of attributes.
                </p>
              </div>
            </div>

            <VpField
              label="Variant Image"
              optional
              note="You can upload new images from the 'Media' menu."
            >
              <MediaModal
                :product-id="Number(productId)"
                v-model="state.mediaIds[0]"
                :selected="selectedMedia"
                @select="selectedMedia = $event"
              />
            </VpField>
            <div class="vp-grid vp-grid-cols-12 vp-gap-2">
              <div class="vp-col-span-6">
                <h6 class="vp-text-gray-500">Base Regular Price</h6>
                <p>{{ baseRegularPrice }}</p>
              </div>
              <div class="vp-col-span-6">
                <h6 class="vp-text-gray-500">Base Selling Price</h6>
                <p>{{ baseSellingPrice }}</p>
              </div>
            </div>
            <price
              :price.sync="state.price"
              :regular-price.sync="state.regularPrice"
            />

            <VpField label="SKU" optional>
              <VpInput>
                <VpTextBox v-model="state.sku" />
              </VpInput>
            </VpField>

            <VpField
              inline
              label="Allow Multiple Quantity in Cart"
              note="Disable this if the product is unique and customer can only add single quantity of item."
            >
              <VpSwitch id="product-qty-options" v-model="state.allowQty" />
            </VpField>

            <VpField
              inline
              label="Out of Stock"
              note="Products will be listed in store but customers will not be able to place order"
            >
              <VpSwitch id="outOfStock" v-model="state.isOutOfStock" />
            </VpField>

            <VpField
              inline
              label="Manage Inventory"
              note="Turn on to manage stock inventory"
            >
              <VpSwitch id="manageInventory" v-model="state.manageInventory" />
            </VpField>

            <VpStatusInput
              v-model="state.status"
              desc="Inactive products will not be listed in store."
            />
          </VpVueForm>

          <!-- Add New Option -->
          <TheOption
            :attribute-id="attributeIdForNewOption"
            :ui-element="uiElementForNewOption"
            @refresh="$refs.request.refresh()"
          />
        </template>
      </VpRequest>
    </template>
  </Create>
</template>

<script>
import ProductAttribute from "components/src/product-attribute.vue";
import ProductAttributeInput from "components/src/product-attribute-input.vue";
import { Add } from "icons/icons.js";
import { isEqual, sortBy } from "lodash-es";
import { mapGetters } from "vuex";

import del from "graph/productVariant/delete.gql";
import get from "graph/productVariant/get.gql";
import meta from "graph/productVariant/meta.gql";
import upsert from "graph/productVariant/upsert.gql";

import TheOption from "../../product-attribute/_Option.vue";
import MediaModal from "../_MediaModal.vue";
import Price from "../_Price.vue";
import Create from "./_Create.vue";

export default {
  icons: {
    Add,
  },
  props: {
    productId: [Number, String],
    variantId: [Number, String],
  },
  components: {
    Price,
    Create,
    MediaModal,

    TheOption,
    ProductAttributeInput,
    ProductAttribute,
  },

  data() {
    return {
      attributes: null,
      existingVariants: null,
      formState: null,
      selectedMedia: null,
      attributeIdForNewOption: null,
      uiElementForNewOption: null,
      baseSellingPrice: null,
      baseRegularPrice: null,
      fields: [
        { name: "attributeInput", value: [] },
        { name: "mediaIds", value: [] },
        { name: "price", value: null },
        { name: "regularPrice", value: null },
        "sku",
        { name: "allowQty", value: true },
        "isOutOfStock",
        "manageInventory",
        { name: "status", value: "active" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),

    selectedVariant() {
      if (!this.formState) return;
      return this.formState.attributeInput.map(
        (item) => item.attributeId + "-" + item.optionId
      );
    },

    /**
     * Checking if the variant is already exising
     * https://stackoverflow.com/questions/29951293/using-lodash-to-compare-jagged-arrays-items-existence-without-order
     */
    selectedVariantExists() {
      const existing = this.existingVariants?.find((item) =>
        isEqual(sortBy(item), sortBy(this.selectedVariant))
      );
      return existing ? true : false;
    },
  },
  methods: {
    get(id) {
      return this.$query(get, {
        id: Number(this.productId), //Parent product Id
        variantId: Number(id), //Variant product id
      }).then(({ data }) => {
        const productVariant = { ...data.productVariant };
        this.selectedMedia = productVariant.media[0];

        productVariant.attributeInput = productVariant.attributes.map(
          (item) => {
            return {
              attributeId: item.attribute.id,
              optionId: item.option.id,
            };
          }
        );

        if (productVariant.media?.[0]) {
          productVariant.mediaIds = [productVariant.media[0].id];
        }

        return {
          values: productVariant,
          res: {
            productVariant,
          },
        };
      });
    },

    getMeta() {
      return this.$query(meta, {
        id: Number(this.productId),
      }).then(({ data }) => {
        //All Attributes
        this.attributes = data.productAssignedAttributes.data;

        /**
         * This array holds the already created attribute vs option id
         * in a format of <attributeId>-<optionId>
         * When creating a new attribute, check if this variant already exists.
         */
        this.existingVariants = data.productVariants.data.map((variant) => {
          return variant.attributes.map((item) => {
            return item.attribute.id + "-" + item.option.id;
          });
        });

        this.baseSellingPrice = data.product.price;
        this.baseRegularPrice = data.product.regularPrice;
        const price = this.fields.find((field) => field.name == "price");
        const regularPrice = this.fields.find(
          (field) => field.name == "regularPrice"
        );
        price.value = data.product.price;
        regularPrice.value = data.product.regularPrice;

        return {
          attributesCount: data.productAssignedAttributes.data.length,
        };
      });
    },

    save(id, data) {
      if (id) {
        data.variantId = Number(id);
      }
      if (!data.regularPrice) {
        data.regularPrice = null;
      }
      if (!data.mediaIds[0]) {
        data.mediaIds = [];
      }
      data.price = Number(data.price);
      data.regularPrice = Number(data.regularPrice);
      return this.$mutate(upsert, {
        productId: Number(this.productId),
        input: [data],
      }).then(({ data }) => {
        this.$refs.item.evictCache();
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "product",
        });

        this.$router.push({
          name: "product-item-variants",
        });
        return data;
      });
    },

    del(id) {
      this.$cache.evict({
        id: "ROOT_QUERY",
        fieldName: "product",
      });
      return this.$mutate(del, {
        id: parseInt(id),
      }).then(() => {
        this.$router.push({
          name: "product-item-variants",
        });
      });
    },

    addNew(id, uiElement) {
      this.attributeIdForNewOption = id;
      this.uiElementForNewOption = uiElement;
      this.$vayu.modal.open("product-attribute-option");
    },
  },
};
</script>
