<template>
  <div>
    <portal to="header">
      <VpButtonSm
        label="Stock History"
        :to="{ name: 'product-item-stock-history' }"
      />
    </portal>

    <Simple
      :product-id="productId"
      :manage-inventory="manageInventory"
      v-if="type == 'simple' || type == 'digital'"
    />
    <Variants
      :product-id="Number(productId)"
      v-else-if="
        type == 'variable' && checkPermission('PRODUCT_VARIANT', 'read')
      "
    />
    <div v-else></div>
  </div>
</template>

<script>
import Simple from "./_Simple.vue";
import Variants from "./_Variants.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    type: String,
    productId: [Number, String],
    manageInventory: Boolean,
  },
  components: {
    Simple,
    Variants,
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },
};
</script>
