<template>
  <div>
    <Reviews
      :product-id="$route.params.productId"
      filter-id="product-detail-reviews"
      :request-payload="{ noFilterStorage: true }"
    />
  </div>
</template>

<script>
import Reviews from "components/src/reviews/index.vue";

export default {
  components: {
    Reviews,
  },
};
</script>
