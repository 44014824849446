<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-6"
    :save="save"
    :get="get"
    width="400px"
    :del="del"
    #default="{ state }"
    :track="$options.track"
    track-prefix="Shipping Charges"
    collection-cache="shippingCharges"
  >
    <VpField label="Location" name="Location">
      <VueSelect
        v-model.number="state.locationId"
        :reduce="(item) => item.value"
        :is-clearable="true"
        :options="getLocations"
        :isMultiple="false"
      >
        <template #no-options>
          <p class="vp-flex vp-items-center vp-justify-between">
            Location Not Found
            <VpButtonSm
              label="Add New"
              :icon="$options.icons.Add"
              @click.native="
                $router.push({ name: 'shipping-location', params: { id: '+' } })
              "
            />
          </p>
        </template>
      </VueSelect>
    </VpField>

    <div class="vp-grid vp-grid-cols-2 vp-gap-8">
      <VpField
        rules="required|positiveZeroAllowedFloat"
        label="Min Cart Amount"
        name="Min Cart Amount"
      >
        <VpInput :after="currency">
          <VpTextBox type="number" v-model.number="state.minCartValue" />
        </VpInput>
      </VpField>

      <VpField
        rules="required|positiveZeroAllowedFloat"
        label="Max Cart Amount"
        name="Max Cart Amount"
      >
        <VpInput :after="currency">
          <VpTextBox type="number" v-model.number="state.maxCartValue" />
        </VpInput>
      </VpField>
    </div>

    <VpField
      label="Charges"
      name="Charge Amount"
      :rules="
        state.chargeType == 'percentage'
          ? 'required|positiveNonZeroFloat|max_value:100'
          : 'required|positiveNonZeroFloat'
      "
    >
      <div class="vp-flex vp-flex-nowrap vp-items-center">
        <VpInput class="vp-flex-grow">
          <VpTextBox type="number" v-model.number="state.rate" step="0.01" />
        </VpInput>
        <div class="vp-ml-4">
          <VpRadioGroup
            name="charge"
            class="vp-flex"
            v-model="state.chargeType"
            :options="calculateOptions"
          />
        </div>
      </div>
    </VpField>

    <VpStatusInput v-model="state.status" />
  </VpVueForm>
</template>

<script>
import { mapGetters } from "vuex";
import get from "./get.gql";
import upsert from "./upsert.gql";
import del from "./delete.gql";
import locationsQuery from "./locations.gql";
import VueSelect from "components/src/vue-select.vue";
import { Add } from "icons/icons.js";
export default {
  icons: {
    Add,
  },
  components: {
    VueSelect,
  },
  track: {
    id: "Id",
    rate: "Charge Calculation",
    chargeType: "Charge Calculation Method",
    chargeNote: "Charge Note",
    status: "Status",
  },
  props: {
    id: [String, Number],
  },
  data() {
    return {
      fields: [
        "locationId",
        { name: "chargeType", value: "flat" },
        { name: "minCartValue", value: 0 },
        { name: "maxCartValue", value: 0 },
        { name: "status", value: "inactive" },
        { name: "rate", value: 0 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currency: "store/currencySymbol",
    }),

    calculateOptions() {
      return [
        { label: this.currency, value: "flat" },
        { label: "%", value: "percentage" },
      ];
    },
  },

  methods: {
    getLocations() {
      return this.$query(locationsQuery).then(
        ({
          data: {
            shippingLocations: { data },
          },
        }) => {
          return data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
        }
      );
    },
    get(id) {
      return this.$query(get, {
        id,
      }).then(({ data }) => {
        return {
          values: {
            ...data.shippingCharge,
            locationId: data.shippingCharge.location?.id,
          },
          res: data,
        };
      });
    },

    save(id, data) {
      const payload = {
        ...data,
      };

      return this.$mutate(upsert, {
        id,
        input: payload,
      }).then(({ data }) => {
        this.$router.push({ name: "shipping-charges" });
        return data;
      });
    },

    del(id) {
      return this.$mutate(del, {
        id,
      }).then(({ data }) => {
        this.$router.push({ name: "shipping-charges" });

        return data;
      });
    },
  },
};
</script>
