<template>
  <div class="vp-flex vp-flex-nowrap">
    <VpVueForm
      :fields="fields"
      :id="id"
      class="vp-p-6"
      :save="save"
      :get="get"
      width="400px"
      :del="del"
      #default="{ state, response }"
      :track="$options.track"
      track-prefix="Attribute"
      collection-cache="productAttributes"
      feature="PRODUCT_ATTRIBUTE"
    >
      <VpShouldDisplayOnStoreAlert
        v-if="response && !response.shouldDisplayOnStore"
        title="Product attribute not accessible!"
        desc="This product attribute is not accessible on store."
      />
      <VpField rules="required" label="Name" name="Name">
        <VpInput>
          <VpTextBox v-model="state.name" />
        </VpInput>
      </VpField>

      <VpField
        rules="required"
        label="Attribute Interface"
        name="Attribute Interface"
      >
        <VpRadioCard
          class="vp-space-y-2"
          v-model="uiElement"
          :options="uiElements"
          custom
        >
          <template #default="{ option, value }">
            <SelectCard
              :title="option.label"
              :active="option.value === value"
              class="vp-w-full"
            >
              <p>{{ option.desc }}</p>
            </SelectCard>
          </template>
        </VpRadioCard>
      </VpField>
    </VpVueForm>

    <div class="vp-flex-auto">
      <options
        v-if="attributeId && checkPermission('PRODUCT_ATTRIBUTE', 'read')"
        :attribute-id="attributeId"
        :ui-element="uiElement"
        :feature="feature"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "graph/productAttribute/get.gql";
import upsert from "graph/productAttribute/upsert.gql";
import del from "graph/productAttribute/delete.gql";
import SelectCard from "components/src/select-card.vue";
import Options from "./_Options.vue";

export default {
  track: {
    id: "Id",
    name: "Name",
    uiElement: "Interface",
  },
  props: {
    id: [Number, String],
    feature: Object,
  },
  components: {
    SelectCard,
    Options,
  },
  data() {
    return {
      attributeId: null,
      redirect: false,
      uiElement: null,
      fields: ["name", { name: "uiElement" }],
      uiElements: [
        {
          label: "Radio Button",
          value: "radio",
          desc: "Displays all the options of product attribute in button format. For example product sizes, weights or measurements.",
        },
        {
          label: "Dropdown",
          value: "dropdown",
          desc: "When there are lot of options available for an attribute, choose this over a 'Radio'.",
        },
        {
          label: "Color",
          value: "color-palette",
          desc: "Displays all the colors of products as a palette. For creating color variants of a product.",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      campaignId: "biz/id",
      checkPermission: "user/checkPermission",
    }),
  },

  methods: {
    get(id) {
      return this.$query(get, {
        id: id,
      }).then(({ data }) => {
        this.attributeId = data.productAttribute.id;
        this.uiElement = data.productAttribute.uiElement;
        return {
          values: data.productAttribute,
          res: data,
        };
      });
    },

    save(id, data) {
      return this.$mutate(upsert, {
        id,
        input: { name: data.name, uiElement: this.uiElement },
      }).then(({ data }) => {
        this.attributeId = data.upsertProductAttribute.id;
        this.feature.refresh(true);
        this.$root.$emit("rehydrate", this.campaignId, true);
        return data;
      });
    },

    del(id) {
      this.redirect = true;
      return this.$mutate(del, {
        id,
      }).then(({ data }) => {
        this.$root.$emit("rehydrate", this.campaignId, true);
        this.feature.refresh(true);

        this.$router.push({ name: "product-attributes" });

        return data;
      });
    },
  },
};
</script>
