<template>
  <div>
    <VpAmount is-negetive tag="p" :value="amount" />
    <p v-if="type === 'percentage'" class="vp-ml-2">({{ value }}%)</p>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    value: Number,
    amount: Number,
  },
};
</script>
