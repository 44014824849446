<template>
  <div>
    <div class="vp-flex vp-items-center">
      <VpProductMedia
        class="vp-cursor-pointer vp-w-16"
        :item="selected"
        size="100x100"
        @click.native="$vayu.modal.open('product-media')"
      />

      <div class="vp-space-x-1">
        <VyButton
          :icon="$options.icons.Edit"
          @click.native="$vayu.modal.open('product-media')"
          class="button--gray button--muted button--circle button--lg vp-ml-4"
        />

        <VyButton
          v-if="selected"
          :icon="$options.icons.Delete"
          @click.native="
            $emit('input');
            $emit('select');
          "
          class="button--danger button--muted button--circle button--lg"
        />
      </div>
    </div>

    <VpModal
      _class="vp-w-1/2 vp-h-3/4"
      name="product-media"
      title="Select Image"
    >
      <VpList
        ref="list"
        endpoint="productMedias"
        :request-payload="{ query: $options.productMedias }"
        cache="productMedias"
        :params="params"
        :columns="[]"
        :header="false"
        :search="false"
      >
        <template #default="{ response: { items } }">
          <div class="vp-grid vp-grid-cols-4 vp-gap-4 vp-p-6">
            <template v-for="(item, index) in items">
              <div
                v-if="checkMedia"
                :key="`media-${index}`"
                class="vp-rounded-md vp-ring-offset-2 vp-overflow-hidden vp-cursor-pointer"
                :class="{
                  'vp-ring-success-500 vp-ring-4': value == item.media.id,
                  'vp-ring-gray-300 hover:vp-ring-4': value !== item.media.id,
                }"
              >
                <VpProductMedia
                  :item="item.media"
                  @click.native="
                    $emit('input', item.media.id);
                    $emit('select', item.media);
                    $vayu.modal.close('product-media');
                  "
                />
              </div>
            </template>
          </div>
        </template>
      </VpList>
    </VpModal>
  </div>
</template>

<script>
import { Delete, Edit } from "icons/icons.js";
import productMedias from "@/views/product/media/collection.gql";

export default {
  productMedias,
  icons: {
    Edit,
    Delete,
  },
  props: {
    productId: [Number, String],
    value: [Number, String],
    selected: Object,
  },
  computed: {
    params() {
      return {
        productId: this.productId,
      };
    },
  },
  methods: {
    checkMedia(item) {
      if (item.media) {
        return (item.media.mimetype || "image").startsWith("image");
      }
      return false;
    },
  },
};
</script>
