<template>
  <div class="vp-flex vp-space-x-1">
    <VpFile
      label="Add Image"
      :icon="$options.icons.Image"
      @input="openCropper($event)"
      accept=".jpg,.jpeg,.png"
    />
    <VyButton
      :icon="$options.icons.Video"
      label="Add Video"
      class="button--sm button--rounded button--solid button--primary"
      @click.native="$vayu.modal.open('embed-video')"
    />

    <Cropper id="quickReply" :upload="saveImage" save-label="Crop and Upload" />
    <EmbedVideo :save="saveVideo" />
  </div>
</template>

<script>
import upsert from "./upsert.gql";
import Cropper from "components/src/cropper/index.vue";
import EmbedVideo from "./_embed-video.vue";
import { Image, Video, ChevronDown } from "icons/icons.js";

export default {
  icons: {
    Image,
    Video,
    ChevronDown,
  },

  props: {
    productId: [Number, String],
  },
  components: {
    Cropper,
    EmbedVideo,
  },

  methods: {
    openCropper(img) {
      this.$vayu.modal.open("crop-modal--quickReply", {
        img,
        cropperConfig: {
          force: true,
          aspectRatio: 1,
        },
      });
    },

    saveVideo(_, data) {
      return this.$mutate(upsert, {
        productId: Number(this.productId),
        input: data,
      }).then(({ data }) => {
        this.$vayu.modal.close("embed-video");
        this.$emit("refresh");
        return data;
      });
    },

    saveImage({ fileName, dataUrl }) {
      return this.$mutate(upsert, {
        productId: Number(this.productId),
        input: { base64: dataUrl, name: fileName },
      }).then(({ data }) => {
        this.$emit("refresh");
        return data;
      });
    },
  },
};
</script>
