<template>
  <div class="vp-grid vp-grid-cols-2 vp-gap-6 vp-p-4">
    <Assigned
      :product-id="Number(productId)"
      @res="setAssigned"
      ref="assigned"
      @refresh="$refs.available.$refs.list.refresh()"
    />
    <Available
      :product-id="productId"
      :assigned="assigned"
      ref="available"
      @refresh="$refs.assigned.$refs.list.refresh()"
    />
  </div>
</template>

<script>
import Assigned from "./_Assigned.vue";
import Available from "./_Available.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    productId: [Number, String],
  },
  components: {
    Assigned,
    Available,
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },

  data() {
    return {
      assigned: null,
    };
  },

  methods: {
    setAssigned(data) {
      this.assigned = data.items.map((item) => item.id);
    },
  },
};
</script>
