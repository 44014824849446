<template>
  <VpListLayout add-route="product-charge" feature="PRODUCT_CHARGE">
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="product-store-charges"
      :request-payload="{ query: $options.storeCharges }"
      cache="storeCharges"
      class="vp-h-full"
      ref="list"
      title="Product Charges"
      reorder
      @rowClick="
        $router.push({
          name: 'product-charge',
          params: {
            id: $event.id,
          },
        })
      "
      @reorder="reorder('storeCharges', $event)"
      feature="PRODUCT_CHARGE"
    >
      <template #empty>
        <VpFeature the-key="PRODUCT_CHARGE" #default="{ feature }">
          <VpListEmpty :feature="feature" route="product-charge" />
        </VpFeature>
      </template>

      <template #value="{ item }">
        <p class="vp-font-bold">
          <span v-if="item.type == 'percentage'">{{ item.value }}%</span>
          <VpAmount v-else :value="item.value" />
        </p>
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import reorder from "mixins/reorder.js";
import storeCharges from "./collection.gql";

import { mapGetters } from "vuex";

export default {
  storeCharges,
  mixins: [reorder],
  computed: {
    ...mapGetters({
      currency: "store/currencySymbol",
    }),
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "name", type: "string" },
        { name: "value", label: "Charge Amout", type: "string" },
      ];
    },
  },
};
</script>
