<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-6"
    :save="save"
    :get="get"
    :del="move"
    :archive="move"
    width="400px"
    ref="item"
    :track="$options.track"
    collection-cache="pages"
    track-prefix="Biz Page"
    feature="PAGE"
  >
    <template #default="{ state, response, setValue }">
      <VpField rules="required" label="Name" name="Name">
        <VpInput>
          <VpTextBox v-model="state.name" @input="setSlug($event, setValue)" />
        </VpInput>
      </VpField>

      <VpField rules="required|slug" label="Slug" name="Slug">
        <VpInput>
          <VpTextBox v-model="state.slug" />
        </VpInput>
      </VpField>

      <status v-model="state.status" :readonly="false" />

      <VpField label="Cover" optional>
        <VpImageEditor
          id="COVER"
          v-model="cover"
          crop
          :crop-ratio="16 / 9"
          #default="{ value }"
        >
          <div class="vp-w-64">
            <VpMedia type="Image" size="16x9" :value="value" />
          </div>
        </VpImageEditor>
      </VpField>

      <VpField rules="required" label="Content">
        <div>
          <div class="vp-flex vp-items-center vp-space-x-2">
            <VpButtonGraySmMuted
              type="button"
              :label="id !== '+' ? 'Edit' : 'Add'"
              :icon="$options.icons.Edit"
              @click.native="$vayu.modal.open('page-content-editor')"
            />

            <VpButtonGraySmMuted
              type="button"
              v-if="state.content"
              label="Preview"
              @click.native="$vayu.modal.open('page-content-viewer')"
            />

            <template v-if="id != '+' && response?.url">
              <VpButtonGraySmMuted
                type="button"
                :href="response?.url"
                target="_blank"
                :disabled="response.status !== 'published'"
                label="View Page"
                :icon="$options.icons.OpenInNew"
              />
            </template>
          </div>
        </div>

        <viewer :value="state.content" />
        <editor v-model="state.content" />
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import { Edit, OpenInNew } from "icons/icons.js";
import { kebabCase } from "lodash-es";
import { createMedia, deleteMedia } from "utils/presets";

import get from "./get.gql";
import move from "./move.gql";
import upsert from "./upsert.gql";

import Editor from "./_Editor.vue";
import Status from "./_Status.vue";
import Viewer from "./_Viewer.vue";

export default {
  icons: {
    Edit,
    OpenInNew,
  },
  track: {
    id: "Id",
    name: "Name",
    slug: "Slug",
    status: "Status",
    content: "Content",
    cover: {
      key: "Cover Image",
      value: (cover) => cover?.url,
    },
  },
  props: {
    id: [Number, String],
  },
  components: {
    Status,
    Editor,
    Viewer,
  },
  data() {
    return {
      loading: true,
      formState: null,
      cover: {},
      fields: [
        "name",
        "slug",
        "content",
        "coverId",
        { name: "status", value: "draft" },
      ],
      options: [
        {
          label: "Draft",
          value: "draft",
        },
        {
          label: "Published",
          value: "published",
        },
        {
          label: "Inactive",
          value: "inactive",
        },
      ],
    };
  },
  methods: {
    get(id) {
      return this.$query(get, {
        id: id,
      }).then(({ data }) => {
        const { page } = data;
        this.cover = {
          url: page.cover?.url,
        };

        page.coverId = page.cover?.id;
        return {
          values: page,
          res: data,
        };
      });
    },
    async save(id, data) {
      if (this.cover.status == "CHANGE") {
        const uploadedMedia = await createMedia(
          "page_cover",
          [this.cover.url],
          [this.cover.name]
        );
        data.coverId = uploadedMedia[0].id;
      } else if (this.cover.status == "DELETE") {
        await deleteMedia([data.coverId]);
        data.coverId = null;
      }
      const inputs = {
        input: data,
        id,
      };

      return this.$mutate(upsert, inputs).then(({ data }) => {
        this.$router.push({ name: "pages" });
        return data;
      });
    },
    move(id, action) {
      return this.$mutate(move, {
        id: parseInt(id),
        action,
      }).then(() => {
        this.$router.push({ name: "pages" });
      });
    },

    setSlug(value, setField) {
      setField("slug", kebabCase(value));
    },
  },
};
</script>
