<template>
  <VpListLayout add-route="page" feature="PAGE">
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="pages-list"
      cache="pages"
      :request-payload="{ query: $options.pages }"
      :filters.sync="filters"
      :params="params"
      class="vp-h-full"
      ref="list"
      title="Pages"
      :reorder="true"
      @rowClick="
        $router.push({
          name: 'page',
          params: {
            id: $event.id,
          },
        })
      "
      @reorder="reorder('pages', $event)"
      feature="PAGE"
    >
      <template #empty>
        <VpFeature the-key="PAGE" #default="{ feature }">
          <VpListEmpty :feature="feature" route="page" />
        </VpFeature>
      </template>

      <template #filters>
        <list-filter-status v-model="filters.status" :options="statusOptions" />
        <VpListFilterArchived v-model="filters.archived" />
      </template>

      <template #cover="{ item }">
        <VpAvatar
          class="vp-rounded-md"
          v-if="item.cover"
          :image="item.cover.url"
          :name="item.name.length ? item.name : '#'"
          size="sm"
        />
      </template>
      <template #status="{ item }">
        <status :value="item.status" />
      </template>
      <template #actions="{ item }">
        <VyButton
          v-if="item.url"
          :href="item.url"
          target="_blank"
          :disabled="item.status !== 'published'"
          label="View Page"
          title="View Page"
          class="vp-ml-2 button--gray button--sm vp-whitespace-nowrap"
          :icon="$options.icons.OpenInNew"
        />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import reorder from "mixins/reorder.js";
import Status from "./_Status.vue";
import ListFilterStatus from "components/src/vp-list/filter-status.vue";
import pages from "@/views/settings/pages/collection.gql";

import { OpenInNew } from "icons/icons.js";

export default {
  pages,
  icons: {
    OpenInNew,
  },
  mixins: [reorder],
  data() {
    return {
      content: null,
      filters: {
        archived: false,
        status: null,
      },
      statusOptions: [
        {
          label: "All",
          value: null,
        },
        {
          label: "Draft",
          value: "draft",
        },
        {
          label: "Published",
          value: "published",
        },
      ],
    };
  },
  components: {
    Status,
    ListFilterStatus,
  },
  computed: {
    columns() {
      return [
        { name: "_index", label: "#", fix: true, type: "string" },
        { name: "cover", fix: true, type: "string" },
        { name: "name", sortable: true, type: "string" },
        { name: "slug", type: "string" },
        { name: "status", fix: true, type: "string" },
        { name: "actions", fix: true, rowClick: false, type: "string" },
      ];
    },
  },
};
</script>
