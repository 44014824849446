<template>
  <VpListLayout>
    <template #header-start>
      <VpPortalButton label="Bulk Actions" @click.native="checkBulkAction()" />
    </template>

    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="inventories"
      :request-payload="{ query: $options.inventories }"
      :filters.sync="filters"
      class="vp-h-full"
      ref="list"
      title="Inventory"
      cache="products"
      feature="PRODUCT"
    >
      <template #empty>
        <VpFeature the-key="INVENTORY" #default="{ feature }">
          <VpListEmpty :feature="feature" />
        </VpFeature>
      </template>
      <template #filters>
        <VpField label="Status">
          <VpInput>
            <VpSelect
              v-model="filters.status"
              :options="statusOption"
              placeholder="All"
            />
          </VpInput>
        </VpField>
        <VpField label="Stock">
          <VpInput>
            <VpSelect
              v-model="filters.isOutOfStock"
              :options="isOutOfStockOption"
              placeholder="All"
            />
          </VpInput>
        </VpField>
        <VpListFilterArchived v-model="filters.archived" />
      </template>

      <template #media="{ item }">
        <router-link :to="{ name: 'product', params: { productId: item.id } }">
          <VpProductMedia :items="item.media" size="100x100" />
        </router-link>
      </template>

      <template #name="{ item }">
        <p>{{ item.name }}</p>
        <p v-if="item.isOutOfStock" class="vp-text-xs vp-text-danger-500">
          Marked as Out of Stock
        </p>
      </template>

      <template #stock="{ item }">
        <VpProductStock :value="item.stock" :is-out-of-stock="false" />
      </template>

      <template #variant="{ item }">
        <Attributes :data="item.variant.attributes" />
      </template>

      <template #adjust="{ item }">
        <VpField name="Stock">
          <VpInput>
            <VpTextBox
              class="vp-w-32"
              type="number"
              :value="values[item.id]"
              @input="$set(values, item.id, $event)"
              step="1"
            />
          </VpInput>
        </VpField>
      </template>

      <template #save="{ item }">
        <VyButton
          label="Save"
          :loading="saving == item.id"
          :disabled="!canSave(item)"
          @click.native="
            checkPermission('INVENTORY', 'upsert', () => {
              save(item);
            })
          "
          class="button--sm button--rounded"
          :class="{
            'button--primary button--solid': canSave(item),
            'button--gray button--muted': !canSave(item),
          }"
        />
      </template>

      <template #stockAfter="{ item }">
        <VpProductStock :value="afterStock(item)" />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import { mapGetters } from "vuex";
import updateStock from "graph/product/stock/update.gql";
import Attributes from "../product/_Attributes.vue";
import inventories from "@/views/inventory/collection.gql";

export default {
  inventories,
  components: {
    Attributes,
  },
  data() {
    return {
      saving: null,
      values: {},
      filters: {
        archived: false,
        categoryId: null,
        status: "",
        isOutOfStock: "",
        manageInventory: true,
        type: ["simple", "variant", "digital"],
      },
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
      isUserPermitted: "user/isUserPermitted",
    }),

    statusOption() {
      return [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
      ];
    },
    isOutOfStockOption() {
      return [
        { label: "Available", value: "0" },
        { label: "Out of Stock", value: "1" },
      ];
    },
    columns() {
      return [
        { name: "media", fix: true, width: "70px" },
        { name: "name", sortable: true, type: "string" },
        { name: "variant", type: "string" },
        {
          name: "sku",
          label: "SKU",
          fix: true,
          sortable: true,
          type: "string",
        },
        { name: "stock", sortable: true, type: "string" },
        { name: "adjust", label: "Adjust (+/-)", type: "string" },
        { name: "stockAfter", label: "Stock After", type: "string" },
        { name: "save", label: "", type: "string" },
      ];
    },
  },

  methods: {
    checkBulkAction() {
      if (this.isUserPermitted("INVENTORY_BULK_UPLOAD", "upsert")) {
        this.$router.push({ name: "product-inventory-bulk" });
      } else {
        this.$vayu.modal.open("unauthorized-access-modal");
      }
    },

    canSave(item) {
      return this.values[item.id];
    },

    afterStock(item) {
      const currentValue = item.stock || 0;
      const newValue = parseInt(this.values[item.id]) || 0;
      return currentValue + newValue;
    },

    save(item) {
      this.saving = Number(item.id);

      return this.$mutate(updateStock, {
        productId: Number(item.id),
        input: { stock: Number(this.values[Number(item.id)]) },
        id: Number(item.id),
      }).then(({ data }) => {
        this.segmentAnalytics(item.stock, data.updateStock);
        this.values = {};
        this.saving = null;
        this.$refs.list.refresh();
      });
    },

    segmentAnalytics(currentStock, { stock, product }) {
      const {
        id,
        name,
        variant: { attributes },
      } = product;

      const updatedStock = currentStock ? currentStock + stock : stock;

      const payload = {
        "Inventory Product Id": id,
        "Inventory Product Name": name,
        "Inventory Stock": currentStock || 0,
        "Inventory Updated Stock": updatedStock,
      };

      if (attributes.length > 0) {
        attributes.forEach((attr) => {
          const { attribute, option } = attr;
          payload[`Inventory Product ${attribute.name}`] = option.label;
        });
      }

      this.$track("Inventory Updated", payload);
    },
  },
};
</script>
