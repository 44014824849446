<template>
  <VpListLayout>
    <CreditsRequire />

    <VpList
      endpoint="notificationSettings"
      :request-payload="{ query: $options.notificationSettings }"
      cache="notificationSettings"
      :params="params"
      class="vp-h-full"
      ref="list"
      :columns="[]"
      :header="false"
      :search="false"
      :per-page="-1"
      feature="NOTIFICATION_SETTINGS"
    >
      <template #default="{ response: { items } }">
        <ul class="vp-max-w-full">
          <li class="vp-flex vp-items-center vp-border-b">
            <div class="vp-w-2/5 vp-p-6">
              <p class="vp-text-2xl">Feature/Activity</p>
              <p class="vp-text-gray-500 vp-text-xs">
                Configure notifications send to your customers based on the
                activity. You will need credits in your account to trigger the
                notifications.
              </p>
            </div>

            <!-- WhatsApp -->
            <div class="vp-w-1/5 vp-p-6 vp-flex vp-justify-center">
              <div
                class="vp-w-16 vp-h-16 vp-bg-whatsapp-400 vp-flex vp-justify-center vp-items-center vp-text-white vp-rounded-full"
              >
                <VpIcon class="vp-w-8 vp-h-8" :name="$options.icons.Whatsapp" />
              </div>
            </div>

            <!-- Email -->
            <div class="vp-w-1/5 vp-p-6 vp-flex vp-justify-center">
              <div
                class="vp-w-16 vp-h-16 vp-bg-red-400 vp-flex vp-justify-center vp-items-center vp-text-white vp-rounded-full"
              >
                <VpIcon class="vp-w-8 vp-h-8" :name="$options.icons.Email" />
              </div>
            </div>
          </li>

          <li
            class="vp-flex vp-items-center vp-border-b"
            v-for="field in items"
            :key="`field-${field.key}`"
          >
            <div class="vp-w-2/5 vp-p-6">
              <p class="vp-text-base">{{ field.title }}</p>
              <p class="vp-text-gray-500 vp-text-xs">
                {{ field.description }}
              </p>
            </div>
            <!-- WhatsApp -->
            <div
              class="vp-w-1/5 vp-p-6 vp-text-center vp-flex vp-items-center vp-justify-center"
            >
              <VpSwitch
                :id="`whatsApp-field-${field.key}`"
                :value="field.whatsapp.isEnabled"
                @input="saveNotification(field, 'whatsapp', $event)"
              />
            </div>
            <!-- Email -->
            <div
              class="vp-w-1/5 vp-p-6 vp-text-center vp-flex vp-items-center vp-justify-center"
            >
              <VyButton
                v-if="field.key === 'order_created_to_admin'"
                label="Coming soon"
                class="button--gray button--muted button--pill button--xs"
              />
              <VpSwitch
                v-else
                :id="`email-field-${field.key}`"
                :value="field.email.isEnabled"
                @input="saveNotification(field, 'email', $event)"
              />
            </div>
          </li>
        </ul>
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import { Email, Whatsapp } from "icons/icons.js";

import CreditsRequire from "components/src/credits/require.vue";
import upsert from "./upsert.gql";
import notificationSettings from "@/views/settings/customer-alert/collection.gql";

export default {
  notificationSettings,
  icons: {
    Email,
    Whatsapp,
  },
  components: {
    CreditsRequire,
  },
  computed: {
    params() {
      return {
        category: "store",
      };
    },
  },
  methods: {
    saveNotification(field, type, value) {
      const { key, email, whatsapp } = field;
      return this.$mutate(upsert, {
        ...this.params,
        input: {
          key,
          email: {
            isEnabled: type === "email" ? value : email.isEnabled,
          },
          whatsapp: {
            isEnabled: type === "whatsapp" ? value : whatsapp.isEnabled,
          },
        },
      }).then(() => {
        this.$refs.list.refresh();
      });
    },
  },
};
</script>
