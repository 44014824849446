<template>
  <VpBulkAccordion
    label="Change Status"
    :confirm="`Are you sure you want to change status of ${count} products?`"
  >
    <VpVueForm
      :fields="fields"
      :save="save"
      #default="{ state }"
      create-label="Update"
      class="vp-pb-0"
      :has-reset="false"
      collection-cache="products"
      feature="PRODUCT"
    >
      <VpStatusInput
        :disabled="!checkPermission('PRODUCT', 'upsert')"
        v-model="state.status"
        desc="Inactive products will not be listed in store."
      />
    </VpVueForm>
  </VpBulkAccordion>
</template>

<script>
import { mapGetters } from "vuex";
import save from "graph/product/batch/updateProductStatus.gql";

export default {
  props: {
    ids: Array,
    count: Number,
  },

  components: {},

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },

  data() {
    return {
      fields: [{ name: "status", value: "active" }],
    };
  },

  methods: {
    save(id, input) {
      return this.$mutate(save, {
        id: this.ids,
        input,
      }).then(({ data }) => {
        this.$emit("refresh");
        return data;
      });
    },
  },
};
</script>
