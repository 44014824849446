<template>
  <VpModal name="embed-video" title="Add Video" _class="vp-w-1/3">
    <div class="vp-p-6">
      <VpVueForm
        :fields="['url']"
        :id="null"
        class="vp-p-0"
        :save="save"
        :has-reset="false"
      >
        <template #default="{ state }">
          <VpField
            rules="required|url"
            label="Video URL"
            name="Video URL"
            note="Supported Platforms: YouTube, Vimeo, Dailymotion, Facebook, Wistia."
          >
            <template #default>
              <VpInput>
                <VpTextBox v-model="state.url" />
              </VpInput>
            </template>
            <template #end>
              <p
                v-if="state.url && !embedUrl"
                class="vp-text-xs vp-text-danger-500"
              >
                Invalid Video URL. Please check that the URL is from supported
                platforms.
              </p>
            </template>
          </VpField>

          <EmbededVideo :value="state.url" @change="embedUrl = $event" />
        </template>

        <template #footer="{ saveItem, isSaving }">
          <div>
            <VyButton
              label="Save"
              @click.native="saveItem"
              :loading="isSaving"
              :disabled="!embedUrl"
              class="button--primary button--solid button--md button--rounded"
            />
          </div>
        </template>
      </VpVueForm>
    </div>
  </VpModal>
</template>

<script>
import EmbededVideo from "components/src/embeded-video.vue";

export default {
  components: {
    EmbededVideo,
  },
  props: {
    save: Function,
  },

  data() {
    return {
      embedUrl: null,
      fields: ["url"],
    };
  },
};
</script>
