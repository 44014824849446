<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    :save="save"
    :get="get"
    class="vp-p-6"
    :del="del"
    width="400px"
    #default="{ state }"
    :track="$options.track"
    track-prefix="Charges"
    collection-cache="storeCharges"
    feature="PRODUCT_CHARGE"
    cache="storeCharge"
  >
    <VpField rules="required" label="Name" name="Name">
      <VpInput>
        <VpTextBox v-model="state.name" />
      </VpInput>
    </VpField>

    <VpField
      label="Charge Amount"
      name="Charge Amount"
      :rules="
        state.type == 'percentage'
          ? 'required|positiveNonZeroFloat|max_value:100'
          : 'required|positiveNonZeroFloat'
      "
    >
      <div class="vp-flex vp-flex-nowrap vp-items-center">
        <VpInput>
          <VpTextBox
            type="number"
            v-model="state.value"
            step="0.01"
            class="vp-w-80"
          />
        </VpInput>
        <div class="vp-ml-4">
          <VpRadioGroup
            name="charges"
            class="vp-flex"
            v-model="state.type"
            :options="calculateOptions"
          />
        </div>
      </div>
    </VpField>
  </VpVueForm>
</template>

<script>
import { mapGetters } from "vuex";
import del from "./delete.gql";
import upsert from "./upsert.gql";
import get from "./get.gql";

export default {
  track: {
    id: "Id",
    name: "Name",
    type: "Calculation Method",
    value: "Value",
  },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      fields: ["name", { name: "type", value: "flat" }, { name: "value" }],
    };
  },
  computed: {
    ...mapGetters({
      currency: "store/currencySymbol",
    }),

    calculateOptions() {
      return [
        { label: this.currency, value: "flat" },
        { label: "%", value: "percentage" },
      ];
    },
  },

  methods: {
    get(id) {
      return this.$query(get, {
        id: id,
      }).then(({ data }) => {
        return {
          values: data.storeCharge,
          res: data,
        };
      });
    },

    save(id, data) {
      const Payload = {
        ...data,
        value: Number(data.value),
      };
      return this.$mutate(upsert, {
        id,
        input: Payload,
      }).then(({ data }) => {
        this.$router.push({ name: "product-charges" });
        return data;
      });
    },

    del(id) {
      return this.$mutate(del, {
        id,
      }).then(({ data }) => {
        this.$router.push({ name: "product-charges" });
        return data;
      });
    },
  },
};
</script>
