<template>
  <VpListLayout add-route="product-category" feature="PRODUCT_CATEGORY">
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="productCategories"
      :request-payload="{ query: $options.productCategories }"
      cache="productCategories"
      :filters.sync="filters"
      class="vp-h-full"
      ref="list"
      title="Product Categories"
      reorder
      @rowClick="
        $router.push({
          name: 'product-category',
          params: {
            id: $event.id,
          },
        })
      "
      feature="PRODUCT_CATEGORY"
      @reorder="
        reorder('productCategories', $event, {
          perPage: $refs.list.localPerPage,
          page: $refs.list.localPage,
        })
      "
    >
      <template #empty>
        <VpFeature the-key="PRODUCT_CATEGORY" #default="{ feature }">
          <VpListEmpty :feature="feature" route="product-category" />
        </VpFeature>
      </template>

      <template #filters>
        <VpField label="Status">
          <VpInput>
            <VpSelect
              v-model="filters.status"
              :options="statusOption"
              placeholder="All"
            />
          </VpInput>
        </VpField>
        <VpListFilterArchived v-model="filters.archived" />
      </template>

      <template #media="{ item }">
        <VpMedia :value="item.media[0]" />
      </template>

      <template #status="{ item }">
        <VpStatus :value="item.status" />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import reorder from "mixins/reorder.js";
import productCategories from "graph/productCategory/collection.gql";

export default {
  productCategories,
  mixins: [reorder],
  data() {
    return {
      filters: {
        archived: false,
        status: null,
      },
    };
  },
  computed: {
    statusOption() {
      return [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
      ];
    },

    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "media", label: "Image", type: "string" },
        { name: "name", sortable: true, type: "string" },
        {
          name: "productCount",
          label: "Products",
          fix: true,
          type: "string",
          value: (item) => item.categoryStatistics?.productCount,
        },
        {
          name: "activeProductCount",
          label: "Active Products",
          type: "string",
          value: (item) => item.categoryStatistics?.activeProductCount,
        },
        {
          name: "inactiveProductCount",
          label: "Inactive Products",
          type: "string",
          value: (item) => item.categoryStatistics?.inactiveProductCount,
        },
        {
          name: "outOfStockProductCount",
          label: "Out Of Stock Products",
          type: "string",
          value: (item) => item.categoryStatistics?.outOfStockProductCount,
        },
        { name: "status", fix: true, sortable: true },
      ];
    },
  },
};
</script>
