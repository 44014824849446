<template>
  <div class="vp-h-full">
    <VpListLayout>
      <template #header-end>
        <UploadMedia :product-id="productId" @refresh="refresh" />
      </template>

      <VpList
        :per-page="10"
        :columns="columns"
        endpoint="productMedias"
        :request-payload="{ query: $options.productMedias }"
        cache="productMedias"
        :params="params"
        class="vp-h-full"
        ref="list"
        title="Product Media"
        :sortable="true"
        reorder
        @reorder="
          reorder('productMedias', $event, { productId: Number(productId) });
          $emit('refresh');
        "
        :loading="loading"
        :search="false"
        feature="PRODUCT"
      >
        <template #empty>
          <VpErrorPage title="No Media Found!" desc="" label="Upload Now" />
        </template>

        <template #media="{ item }">
          <VpMedia
            v-if="(item.media.mimetype || 'image').includes('image')"
            :value="item.media"
            size="96"
          />
          <div v-else class="vp-w-48">
            <EmbededVideo :value="item.media.url" />
          </div>
        </template>

        <template #actions="{ item }">
          <VyButton
            :icon="$options.icons.Delete"
            @click.native="checkPermission('PRODUCT', 'delete', del(item))"
            class="button--danger button--muted button--square button--sm button--rounded"
          />
        </template>
      </VpList>
    </VpListLayout>
  </div>
</template>

<script>
import EmbededVideo from "components/src/embeded-video.vue";
import { Delete } from "icons/icons.js";
import { deleteMedia } from "utils/presets";
import { mapGetters } from "vuex";

import reorder from "mixins/reorder.js";

import UploadMedia from "./_UploadMedia.vue";
import productMedias from "@/views/product/media/collection.gql";

export default {
  productMedias,
  icons: {
    Delete,
  },
  props: {
    productId: [Number, String],
  },
  components: {
    UploadMedia,
    EmbededVideo,
  },
  data() {
    return {
      loading: false,
    };
  },
  mixins: [reorder],
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),

    params() {
      return {
        productId: Number(this.productId),
      };
    },

    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "media", type: "string" },
        { name: "actions", type: "string", fix: true },
      ];
    },
  },
  methods: {
    async del(item) {
      this.$vayu.confirm({
        name: "delete",
        title: "Delete Media?",
        message:
          "Are you sure you want to delete this media? This can not be undone.",

        ok: async ({ close }) => {
          this.loading = true;
          close();
          await deleteMedia(item.media.id);
          this.$emit("refresh");
          this.$refs.list.refresh();
          this.loading = false;
        },
        cancel: ({ close }) => {
          close();
        },
      });
    },

    refresh() {
      this.$refs.list.refresh();
      this.$emit("refresh");
    },
    imageUrl(item) {
      return item?.["size"]?.["_100x100"];
    },
  },
};
</script>
