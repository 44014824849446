<template>
  <div>
    <Input
      v-if="
        field.inputType == 'text' ||
        field.inputType == 'dropdown' ||
        field.inputType == 'date'
      "
    >
      <component
        :is="`The${field.inputType}`"
        v-model="computedValue"
        v-bind="attrs"
        @input="$emit('input', $event)"
      />
    </Input>
    <component
      v-else
      :is="`The${field.inputType}`"
      v-model="computedValue"
      v-bind="attrs"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import CheckboxGroup from "./form/checkbox-group.vue";
import TextBox from "./form/textbox.vue";
import TextArea from "./form/textarea.vue";
import Select from "./form/select.vue";
import RadioGroup from "./form/radio-group.vue";
import Datepicker from "./datepicker.vue";
import Input from "./form/input.vue";

export default {
  components: {
    Thecheckbox: CheckboxGroup,
    Thetext: TextBox,
    Thetextarea: TextArea,
    Thedropdown: Select,
    Theradio: RadioGroup,
    Thedate: Datepicker,
    Themap: "", // TODO: Render Map
    Input,
  },
  props: ["value", "field"],
  data() {
    return {
      computedValue: this.value,
    };
  },
  computed: {
    attrs() {
      let attrs = {};
      switch (this.field.inputType) {
        case "date":
          attrs = {
            config: this.field.config,
            class: "vp-w-full",
          };
          break;

        case "textarea":
          attrs = { rows: 5 };
          break;

        case "dropdown":
          attrs = {
            placeholder: `Select ${this.field.name}`,
            options: this.field.options.map((opt) => {
              return {
                label: opt.label,
                value: opt.id,
              };
            }),
          };
          break;

        case "radio":
        case "checkbox":
          attrs = {
            options: this.field.options.map((opt) => {
              return {
                label: opt.label,
                value: opt.id,
              };
            }),
            name: `${this.field.name}-${this.field.id}`,
            class: "!vp-space-x-0 vp-gap-4 vp-p-2",
          };
          break;
      }

      return attrs;
    },
  },
};
</script>
