<template>
  <VpSubShell>
    <template #nav>
      <VpNavGroup is-first>Categories</VpNavGroup>
      <div class="vp-flex vp-space-y-1 vp-flex-col">
        <VpSubNavItem
          label="All"
          wrap
          :to="{
            name: 'products',
          }"
          exact
        />
        <VpSubNavItem
          wrap
          v-for="category in categoryOptions"
          :label="category.label"
          :key="`cat-${category.value}`"
          :to="{
            name: 'products-by-category',
            params: { categoryId: category.value },
          }"
        />
      </div>
    </template>
    <template #default>
      <List
        :category-id="categoryId"
        :key="categoryId"
        :feature="feature"
        :refresh="refresh"
      />
    </template>
  </VpSubShell>
</template>
<script>
import { productCategoryOptions } from "utils/presets";
import List from "./_List.vue";

export default {
  props: {
    categoryId: [Number, String],
    feature: Object,
    refresh: Function,
  },

  components: {
    List,
  },

  data() {
    return {
      categoryOptions: [],
    };
  },

  created() {
    this.getProductCategory();
  },

  watch: {
    categoryId() {
      this.setBreadcrumb();
    },
  },

  methods: {
    async getProductCategory() {
      this.categoryOptions = await productCategoryOptions();
      this.setBreadcrumb();
    },

    setBreadcrumb() {
      if (!this.categoryId) return;

      const category = this.categoryOptions.find(
        (item) => item.value == this.categoryId
      );

      this.$root.$emit(
        "breadcrumbs-label",
        "products-by-category",
        category.label
      );
    },
  },
};
</script>
