<template>
  <div>
    <div
      class="vp-group vp-p-2 vp-w-[208px] vp-flex vp-gap-2 vp-items-center vp-border-r vp-border-gray-200"
    >
      <TheAvatar
        :name="storeName.charAt(0)"
        size="xxs"
        class="vp-shrink-0 vp-text-gray-500 vp-font-bold group-hover:vp-text-black"
      />
      <p class="vp-grow vp-text-center vp-font-semibold vp-truncate">
        {{ storeName }}
      </p>
    </div>
  </div>
</template>

<script>
import TheAvatar from "components/src/avatar.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    TheAvatar,
  },
  computed: {
    ...mapGetters({
      storeName: "store/name",
    }),
  },
};
</script>
