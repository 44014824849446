<template>
  <VpVueForm
    :fields="fields"
    ref="item"
    class="vp-p-6"
    :id="id"
    :get="get"
    #default="{ response }"
  >
    <VpField label="Customer">
      <VpConnection has-link type="contact" :data="response?.customer" />
    </VpField>

    <div class="order-detail-tables">
      <VpPageHeader title="Order" />
      <VyTable
        class="table--relaxed table--border-y table--border-edge vp-w-full"
      >
        <tr>
          <td width="140px">Placed</td>
          <td><VpDatetime :value="response.placedAt" /></td>
        </tr>

        <tr>
          <td>Payment Mode</td>
          <td>
            <div class="vp-flex vp-items-center">
              <p class="vp-mr-2">
                {{
                  response.orderPaymentMode &&
                  response.orderPaymentMode.displayName
                }}
              </p>
              <VyButton
                :icon="$options.icons.Edit"
                @click.native="paymentModeHandler(response.orderPaymentMode)"
                class="button--primary button--muted button--circle button--xs"
              />
            </div>
          </td>
        </tr>
        <tr>
          <!-- TODO: Show Conditionally -->
          <td>Transaction ID</td>
          <td>
            {{
              response.orderPaymentMode &&
              response.orderPaymentMode.providerTransactionId
            }}
          </td>
        </tr>

        <tr>
          <td>Ship To</td>
          <td>{{ response.shipping && response.shipping.name }}</td>
        </tr>
        <tr>
          <td>Order By</td>
          <td>{{ response.orderBy }}</td>
        </tr>
        <tr>
          <td>Admin Note</td>
          <td>{{ response.adminNote }}</td>
        </tr>
      </VyTable>

      <VpPageHeader class="vp-mt-4" title="Address" />
      <VyTable
        class="vp-w-full table--relaxed table--border-y table--border-edge"
      >
        <template v-for="field in checkoutOtherFields">
          <tr v-if="response.orderCheckoutForm[field.name]" :key="field.name">
            <td width="140px">{{ field.label }}</td>
            <td>{{ response.orderCheckoutForm[field.name] }}</td>
          </tr>
        </template>

        <template v-if="response.orderCheckoutForm.address">
          <template v-for="field in checkoutAddressFields">
            <tr
              v-if="response.orderCheckoutForm.address[field.name]"
              :key="field.name"
            >
              <td width="140px">{{ field.label }}</td>
              <td>{{ response.orderCheckoutForm.address[field.name] }}</td>
            </tr>
          </template>
        </template>
      </VyTable>

      <!-- Custom Fields -->
      <template v-if="response.customFields.length">
        <VpPageHeader class="vp-mt-4" title="Custom Fields" />
        <VyTable
          class="vp-w-full table--relaxed table--border-y table--border-edge"
        >
          <tr v-for="field in response.customFields" :key="field.id">
            <td width="140px">{{ field.name }}</td>
            <td>
              <p
                v-if="
                  ['checkbox', 'radio', 'dropdown'].includes(field.inputType)
                "
              >
                {{ fieldOptions(field) }}
              </p>
              <p v-else-if="field.inputType === 'date'">
                {{ new Date(field.value).toLocaleDateString() }}
              </p>
              <template v-else-if="field.inputType === 'map'">
                <div class="vp-max-w-sm">
                  <Map :value="field.value" />
                </div>
              </template>
              <p v-else>{{ field.value }}</p>
            </td>
          </tr>
        </VyTable>
      </template>

      <VpPageHeader class="vp-mt-4" title="Amount" />
      <VyTable
        class="vp-w-full table--relaxed table--border-y table--border-edge"
      >
        <tr>
          <td width="140px">Subtotal</td>
          <td><VpAmount :value="response.subTotal" /></td>
        </tr>
        <tr>
          <td>Discount</td>
          <td>
            <discount-display
              :value="response.discountValue"
              :amount="response.discountAmount"
              :type="response.discountType"
            />
          </td>
        </tr>
        <tr v-if="response.appliedCoupon">
          <td>
            <p>Coupon Discount</p>
            <p class="vp-text-gray-500 vpm-text-sm">
              ({{ response.appliedCoupon.code }})
            </p>
          </td>
          <td>
            <discount-display
              :value="response.appliedCoupon.discountValue"
              :amount="response.appliedCoupon.discountedAmount"
              :type="response.appliedCoupon.discountType"
            />
          </td>
        </tr>

        <tr v-if="response.checkoutAddonCharge">
          <td>Addon Charges</td>
          <td><VpAmount :value="response.checkoutAddonCharge" /></td>
        </tr>

        <tr>
          <td>Shipping Charge</td>
          <td><VpAmount :value="response.shippingCharge" /></td>
        </tr>
        <tr>
          <td>Taxes & Charges</td>
          <td><VpAmount :value="response.additionalCharge" /></td>
        </tr>

        <tr>
          <td>Adjustment</td>
          <td><VpAmount :value="response.adjustment" /></td>
        </tr>
        <tr>
          <td>Total</td>
          <td><VpAmount :value="response.total" /></td>
        </tr>
      </VyTable>

      <VyButton
        type="button"
        label="Delete Order"
        :icon="$options.icons.Delete"
        :loading="deleting"
        @click.native="checkPermission('ORDER', 'delete', del)"
        class="vp-mt-12 button--danger button--muted button--md button--rounded"
      />

      <payment-mode-update
        :current-mode="currentMode"
        :params="params"
        @refresh="refresh"
      />
    </div>
  </VpVueForm>
</template>

<script>
import { Delete, Edit } from "icons/icons.js";
import { mapGetters } from "vuex";

import Map from "./_map.vue";
import del from "../delete.gql";
import details from "../details.gql";

import DiscountDisplay from "../_DiscountDisplay.vue";
import PaymentModeUpdate from "./_PaymentModeUpdate.vue";

export default {
  icons: {
    Edit,
    Delete,
  },
  props: {
    id: [Number, String],
  },
  components: {
    PaymentModeUpdate,
    DiscountDisplay,
    Map,
  },
  data() {
    return {
      fields: [],
      checkoutOtherFields: [
        { name: "email", label: "Email" },
        { name: "customerNote", label: "Customer Note" },
      ],
      checkoutAddressFields: [
        { name: "name", label: "Name" },
        { name: "address", label: "Address" },
        { name: "addressLine1", label: "Address Line 1" },
        { name: "addressLine2", label: "Address Line 2" },
        { name: "landmark", label: "Landmark" },
        { name: "locality", label: "Locality" },
        { name: "city", label: "City" },
        { name: "state", label: "State" },
        { name: "country", label: "Country" },
        { name: "pincode", label: "Pincode/Zipcode" },
        { name: "gstNumber", label: "GST number" },
        { name: "taxNumber", label: "VAT number" },
      ],
      currentMode: null,
      deleting: false,
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),

    params() {
      return {
        orderId: this.id,
      };
    },
  },

  methods: {
    fieldOptions(field) {
      const value =
        typeof field.value === "string" ? [field.value] : field.value;
      const selectedOptions = field.options
        .map((opt) => value?.includes(opt.id) && opt.label)
        .filter((t) => t);
      return selectedOptions.join(", ");
    },

    get(id) {
      return this.$query(details, {
        id: id,
      }).then(({ data }) => {
        return {
          values: data.order,
          res: data,
        };
      });
    },

    paymentModeHandler(mode) {
      this.currentMode = {
        paymentModeId: mode?.id,
        displayName: mode?.name,
      };
      this.$vayu.modal.open("update-payment-mode-modal");
    },

    refresh() {
      this.$emit("refresh");
    },

    del() {
      this.$vayu.confirm({
        name: "delete",
        title: "Delete Order?",
        message:
          "This can not be undone. Are you sure you want to delete this order?",

        ok: async ({ close }) => {
          close();
          this.deleting = true;
          return this.$mutate(del, {
            id: this.id,
          })
            .then(() => {
              this.$cache.evict({
                id: "ROOT_QUERY",
                fieldName: "orders",
              });
              this.$track("Order Deleted", {
                "Order Id": this.id,
              });
              this.deleting = false;
              this.$root.$emit("rehydrate", null, true);
              this.$router.push({
                name: "orders",
              });
            })
            .catch((err) => {
              console.error(err);
              this.deleting = false;
            });
        },
        cancel: ({ close }) => {
          close();
        },
      });
    },
  },
};
</script>

<style>
.order-detail-tables {
  td:nth-child(1) {
    @apply vp-text-gray-500;
  }
  td:nth-child(2) {
    white-space: pre-wrap;
  }
}
</style>
