<template>
  <VpListLayout
    :add-route="{ name: 'product-content-item', params: { fileId: '+' } }"
  >
    <VpList
      :columns="columns"
      endpoint="productContents"
      :request-payload="{ query: $options.productContents }"
      :params="params"
      class="vp-h-full"
      ref="list"
      title="Content"
      collection-cache="productContents"
      :search="false"
      reorder
      @reorder="reorder('productContents', $event, params)"
      @rowClick="
        (item) =>
          $router.push({
            name: 'product-content-item',
            params: {
              fileId: item.id,
            },
          })
      "
      feature="PRODUCT_CONTENT"
    >
      <template #media="{ item: { thumb } }">
        <div class="product-list-media">
          <VpProductMedia :item="thumb" size="100x100" />
        </div>
      </template>
      <template #name="{ item: { name } }">
        <p>{{ name }}</p>
      </template>

      <template #description="{ item: { description } }">
        <p class="vp-text-gray-500 vp-max-w-md vp-line-clamp-2">
          {{ description }}
        </p>
      </template>

      <template #url="{ item: { isUrl, file } }">
        <VyButton
          v-if="isUrl"
          :icon="$options.icons.OpenInNew"
          label="View"
          :href="file && file.url"
          target="_blank"
          class="button--gray button--sm button--rounded button--muted"
        />
        <VyButton
          v-else
          :icon="$options.icons.Download"
          label="Download"
          :href="file && file.url"
          target="_blank"
          class="button--gray button--muted button--sm button--rounded"
        />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import reorder from "mixins/reorder.js";
import { Download, OpenInNew } from "icons/icons.js";
import productContents from "graph/product/content/collection.gql";

export default {
  productContents,
  icons: {
    Download,
    OpenInNew,
  },
  props: {
    productId: [Number, String],
  },
  mixins: [reorder],
  computed: {
    params() {
      return {
        productId: Number(this.productId),
      };
    },
    columns() {
      return [
        { name: "_index", label: "#", fix: true, type: "string" },
        { name: "media", fix: true, width: "70px", type: "string" },
        { name: "name", type: "string" },
        { name: "description", type: "string" },
        { name: "url", type: "number", label: "Actions", rowClick: false },
      ];
    },
  },
};
</script>
