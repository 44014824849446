<template>
  <VpProFeature title="History" feature="PRODUCT_BULK_UPLOAD">
    <ProcessExcelHistory module="product" feature="PRODUCT_BULK_UPLOAD" />
  </VpProFeature>
</template>

<script>
import ProcessExcelHistory from "components/src/process-excel/history.vue";

export default {
  components: {
    ProcessExcelHistory,
  },
};
</script>
