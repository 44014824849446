<template>
  <VpListLayout>
    <template #header-start>
      <VpButtonSm
        :disabled="count >= 3"
        :icon="$options.icons.Add"
        @click.native="
          checkPermission('STORE_BANNER', 'upsert', () =>
            $router.push({ name: 'banner', params: { id: '+' } })
          )
        "
        label="Add New"
      />
    </template>

    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="storeBanners"
      :request-payload="{ query: $options.storeBanners }"
      :sortable="true"
      cache="storeBanners"
      class="vp-h-full"
      ref="list"
      title="Banners"
      @rowClick="
        $router.push({
          name: 'banner',
          params: {
            id: $event.id,
          },
        })
      "
      reorder
      @reorder="reorder('storeBanners', $event)"
      @res="getCount"
      feature="STORE_BANNER"
      :loading="loading"
    >
      <template #empty>
        <VpFeature the-key="STORE_BANNER" #default="{ feature }">
          <VpListEmpty :feature="feature" route="banner" />
        </VpFeature>
      </template>
      <template #media="{ item }">
        <!-- TODO: Avatar component should not be used here -->
        <VpAvatar
          :image="imageUrl(item.media)"
          :name="item.name"
          size="xl"
          class="vp-rounded vp-bg-gray-100"
        />
      </template>

      <template #actions="{ item }">
        <VyButton
          :icon="$options.icons.Delete"
          @click.native="
            checkPermission('STORE_BANNER', 'delete', () => {
              del(item);
            })
          "
          class="button--danger button--muted button--square button--sm button--rounded"
        />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import { Add, Delete } from "icons/icons.js";
import { mapGetters } from "vuex";
import reorder from "mixins/reorder.js";
import storeBanners from "@/views/settings/banners/collection.gql";

import del from "./delete.gql";

export default {
  storeBanners,
  mixins: [reorder],
  icons: {
    Add,
    Delete,
  },

  data() {
    return {
      loading: false,
      count: null,
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),

    columns() {
      return [
        { name: "_index", label: "#", type: "string" },
        { name: "media", type: "string" },
        { name: "actions", type: "string", rowClick: false },
      ];
    },
  },
  methods: {
    async del(item) {
      this.$vayu.confirm({
        name: "delete",
        title: "Delete Banner?",
        message:
          "Are you sure you want to delete this banner? This can not be undone.",

        ok: async ({ close }) => {
          this.loading = true;
          close();
          return this.$mutate(del, {
            id: parseInt(item.id),
          }).finally(() => {
            this.$track("Store Banner Deleted", {
              "Store Banner Id": item.id,
              "Store Banner Image": item.media?.["size"]?.["_700x394"],
              "Store Banner URL": item.url,
            });
            this.$refs.list.refresh();
            this.loading = false;
          });
        },
        cancel: ({ close }) => {
          close();
        },
      });
    },

    imageUrl(item) {
      return item?.["size"]?.["_700x394"];
    },
    getCount(data) {
      this.count = data.count;
    },
  },
};
</script>
