<template>
  <div class="vp-h-full">
    <!-- Header Buttons -->
    <VpHeaderActions>
      <VpButtonSm
        :to="{ name: 'shipping-charge', params: { id: '+' } }"
        :icon="$options.icons.Add"
        label="Add New"
      />
    </VpHeaderActions>

    <!-- List -->
    <VpList
      :per-page="10"
      :columns="columns"
      :search="false"
      endpoint="shippingCharges"
      :request-payload="{ query: $options.shippingCharges }"
      cache="shippingCharges"
      @reorder="reorder('shippingCharges', $event)"
      :reorder="true"
      reorder-label="Priority"
      class="vp-h-full"
      ref="list"
      title="Shipping Charges"
      @rowClick="
        $router.push({
          name: 'shipping-charge',
          params: {
            id: $event.id,
          },
        })
      "
    >
      <template #empty>
        <VpErrorPage
          title="Shipping Charges Not Found!"
          desc="You can Mention the shipping charges as per your delivery location."
        >
          <template #actions>
            <VpButtonSm
              :icon="$options.icons.Add"
              label="Add New"
              :to="{ name: 'shipping-charge', params: { id: '+' } }"
            />
          </template>
        </VpErrorPage>
      </template>

      <template #rate="{ item }">
        <p class="vp-font-bold">
          <span v-if="item.chargeType == 'percentage'"> {{ item.rate }}% </span>
          <VpAmount v-else :value="item.rate" />
        </p>
      </template>

      <template #minCartValue="{ item }">
        <VpAmount :value="item.minCartValue" />
      </template>

      <template #maxCartValue="{ item }">
        <VpAmount :value="item.maxCartValue" />
      </template>

      <template #location="{ item }">
        <div v-if="item.location">
          {{ item.location.name }}
        </div>
      </template>

      <template #status="{ item }">
        <VpStatus :value="item.status" />
      </template>
    </VpList>
  </div>
</template>

<script>
import { Help, Add } from "icons/icons.js";
import shippingCharges from "./collection.gql";
import reorder from "mixins/reorder.js";

export default {
  mixins: [reorder],
  shippingCharges,
  icons: {
    Help,
    Add,
  },

  computed: {
    columns() {
      return [
        {
          name: "minCartValue",
          label: "Min Cart Amount",
          type: "string",
        },
        {
          name: "maxCartValue",
          label: "Max Cart Amount",
          type: "string",
        },

        { name: "rate", type: "string", label: "Charges" },

        { name: "location", type: "string" },

        { name: "status", fix: true },
      ];
    },
  },
};
</script>
