<template>
  <div>
    <VpPageHeader title="Assigned" class="vp-border-b vp-pb-2" />
    <VpList
      :columns="columns"
      endpoint="productAssignedAttributes"
      :request-payload="{ query: $options.productAssignedAttributes }"
      cache="productAssignedAttributes"
      :params="params"
      class="vp-h-full"
      ref="list"
      title="Product Assigned Attributes"
      :search="false"
      :header="false"
      reorder
      @reorder="
        reorder('productAssignedAttributes', $event, {
          productId,
          perPage: $refs.list.localPerPage,
          page: $refs.list.localPage,
        })
      "
      @res="$emit('res', $event)"
      feature="PRODUCT_ATTRIBUTE"
    >
      <template #empty>
        <div class="vp-text-sm vp-text-gray-500 vp-mt-4">
          No attributes assigned to the product. To create variants, please
          assign the attribute from the "Available" list.
        </div>
      </template>

      <template
        #actions="{
          item,
          response: {
            res: { data },
          },
        }"
      >
        <VyButton
          type="button"
          v-if="
            data.product.variantCount == 0 &&
            checkPermission('PRODUCT_ATTRIBUTE', 'upsert')
          "
          @click.native="remove(item.id)"
          :icon="$options.icons.Delete"
          :loading="item.id == saving"
          class="button--danger button--muted button--sm button--square button--rounded"
        />
      </template>
    </VpList>
  </div>
</template>

<script>
import reorder from "mixins/reorder.js";
import toggle from "graph/product/toggleAttribute.gql";
import { Delete } from "icons/icons.js";
import productAssignedAttributes from "graph/productAssignedAttribute/collection.gql";
import { mapGetters } from "vuex";
export default {
  productAssignedAttributes,
  icons: {
    Delete,
  },
  props: {
    productId: [Number, String],
  },
  mixins: [reorder],
  data() {
    return {
      saving: null,
    };
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),

    params() {
      return {
        productId: this.productId,
      };
    },

    columns() {
      return [
        { name: "name", type: "string" },
        { name: "actions", label: "", fix: true, type: "string" },
      ];
    },
  },

  methods: {
    async remove(id) {
      this.saving = id;
      try {
        await this.$mutate(toggle, {
          productId: Number(this.productId),
          attributeId: Number(id),
        });
      } catch (err) {
        console.error(err);
      }
      this.saving = null;
      this.$refs.list.refresh();
      this.$emit("refresh");
    },
  },
};
</script>
