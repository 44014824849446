<template>
  <VpModal
    name="product-attribute-presets"
    title="Add Product Attribute"
    _class="vp-w-1/2"
  >
    <VpLoaderContainer :loading="saving" overlay>
      <div class="vp-p-6">
        <p class="">
          With the help of product attributes, you can create product variants.
          Attributes & it's options are created at global level and can be used
          in all the products.
        </p>
        <VpRequest :get="get" #default="{ res }">
          <div class="vp-grid vp-grid-cols-3 vp-gap-4 vp-mt-6">
            <VerticalCard
              :disabled="!checkPermission('PRODUCT_ATTRIBUTE', 'upsert')"
              v-for="(preset, index) in res"
              :key="`preset-${index}`"
              :icon="$options.icons[preset.name]"
              :title="preset.name"
              :attrs="preset.descList"
              @click.native="save(preset)"
            />
          </div>
        </VpRequest>

        <div class="vp-mt-4">
          <p>Not listed here?</p>
          <VyButton
            label="Create Custom Attribute"
            :icon="!canCreateCustomAttribute ? $options.icons.Upgrade : ''"
            class="button--sm button--solid button--rounded vp-mt-2"
            :class="
              !canCreateCustomAttribute ? 'button--warning' : 'button--primary'
            "
            @click.native="redirectToProductAttribute"
          />
        </div>
      </div>
    </VpLoaderContainer>
  </VpModal>
</template>

<script>
import { Size, Theme, Upgrade, Weight } from "icons/icons.js";
import { mapGetters } from "vuex";

import VerticalCard from "@/components/vertical-card.vue";
import get from "graph/productAttribute/presets.gql";
import upsert from "graph/productAttribute/upsert.gql";
import { toSubscription } from "plugins/utils.js";

export default {
  icons: {
    Size,
    Weight,
    Color: Theme,
    Upgrade,
  },
  components: {
    VerticalCard,
  },

  data() {
    return {
      saving: false,
    };
  },

  computed: {
    ...mapGetters({
      campaignId: "biz/id",
      checkPermission: "user/checkPermission",
      planId: "subscription/planId",
    }),

    canCreateCustomAttribute() {
      return this.planId > 0;
    },
  },

  methods: {
    get() {
      return this.$query(get).then(({ data }) => {
        return data.metaData.productAttributePresets;
      });
    },

    redirectToProductAttribute() {
      if (this.canCreateCustomAttribute === false) {
        this.$track("Upgrade Button Clicked", {
          Source: "Popup",
          Feature: "PRODUCT_ATTRIBUTE",
        });

        toSubscription();
      } else {
        this.$router.push({ name: "product-attribute", params: { id: "+" } });
      }
    },

    save(preset) {
      this.saving = true;
      this.$mutate(upsert, {
        input: {
          name: preset.name,
          uiElement: preset.uiElement,
        },
      })
        .then(({ data }) => data.upsertProductAttribute)
        .then((res) => {
          this.$track("Attribute Created", {
            "Attribute Id": res.id,
            "Attribute Name": res.name,
            "Attribute Interface": res.uiElement,
          });
          this.saving = false;
          this.$root.$emit("rehydrate", this.campaignId, true);
          if (res.id != this.id) {
            this.$router.push({
              name: "product-attribute",
              params: {
                id: res.id,
              },
            });
          }
        })
        .catch((err) => {
          console.error(err);
          this.saving = false;
        });
    },
  },
};
</script>
