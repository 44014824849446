<template>
  <div class="vp-p-6">
    <ProcessExcel
      :mutation-query="process"
      action="create"
      module="product"
      feature="PRODUCT_BULK_UPLOAD"
    >
      <template #headsup>
        <HeadsUp />
      </template>
    </ProcessExcel>
  </div>
</template>

<script>
import process from "graph/excel/process.gql";
import ProcessExcel from "components/src/process-excel/main.vue";
import HeadsUp from "./_HeadsUp.vue";

export default {
  data() {
    return {
      process,
    };
  },
  components: {
    ProcessExcel,
    HeadsUp,
  },
};
</script>
