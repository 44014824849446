<template>
  <div class="vp-h-full">
    <!-- Header Buttons -->
    <VpHeaderActions>
      <VpAddItem :feature="feature" @click="onAdd" />
    </VpHeaderActions>

    <VpList
      :per-page="25"
      :columns="columns"
      endpoint="productAttributes"
      :request-payload="{ query: $options.productAttributes }"
      cache="productAttributes"
      class="vp-h-full"
      ref="list"
      title="Product Attributes"
      @rowClick="
        $router.push({
          name: 'product-attribute',
          params: {
            id: $event.id,
          },
        })
      "
      feature="PRODUCT_ATTRIBUTE"
    >
      <template #empty>
        <VpListEmpty :feature="feature">
          <template #action>
            <VpAddItem @click="onAdd" :feature="feature" />
          </template>
        </VpListEmpty>
      </template>

      <template #name="{ item }">
        <LockedItem :item="item" />
        <p>{{ item.name }}</p>
      </template>

      <template #uiElement="{ item }">
        <p class="vp-capitalize">{{ item.uiElement }}</p>
      </template>
    </VpList>

    <Presets :store-id="storeId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Presets from "./_Presets.vue";
import { Help } from "icons/icons.js";
import LockedItem from "components/src/vp-list/locked-item.vue";
import productAttributes from "graph/productAttribute/collection.gql";

export default {
  productAttributes,
  icons: {
    Help,
  },

  components: {
    Presets,
    LockedItem,
  },

  props: ["feature"],

  computed: {
    ...mapGetters({
      storeId: "store/id",
      isSubscriptionLimitExceeded: "user/isSubscriptionLimitExceeded",
      checkPermission: "user/checkPermission",
    }),

    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        {
          name: "name",
          type: "string",
        },
        { name: "uiElement", label: "Interface", type: "string" },
      ];
    },
  },

  methods: {
    onAdd() {
      this.$vayu.modal.open("product-attribute-presets");
    },
  },
};
</script>
