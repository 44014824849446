<template>
  <Async :req="req">
    <!-- Shimmers -->
    <template #pending>
      <div class="vp-h-9 vp-bg-shimmer vp-rounded-md vp-animate-pulse" />
    </template>

    <template #default="{ res }">
      <VpInput>
        <VpSelect
          :value="value"
          @input="$emit('input', parseInt($event))"
          :options="res"
        />
      </VpInput>
    </template>
  </Async>
</template>

<script>
import Async from "../async.vue";
import get from "./get.gql";
import defaultPaymentModes from "./default-options.json?inline";

export default {
  props: {
    value: {
      type: Number,
    },
  },

  components: {
    Async,
  },

  methods: {
    req() {
      return this.$query(get).then(({ data }) => {
        const paymentModes = data.storePaymentModes.data
          .filter((item) => item.isConfigured)
          .map((item) => {
            return {
              label: item.name,
              value: item.providerId,
            };
          });

        return [...defaultPaymentModes, ...paymentModes];
      });
    },
  },
};
</script>
