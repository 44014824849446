import Vue from "vue";
import VueRouter from "vue-router";
import Hydrate from "@/views/hydrate/index.vue";
import products from "./products";
import order from "./order";
import inventory from "./inventory";
import settings from "./settings/index.js";
import { getToken } from "plugins/utils";
import { pageView } from "plugins/segment";
const { VITE_WEB_URL } = import.meta.env;
import Dashboard from "@/views/dashboard/index.vue";

import ProductCategoryIndex from "@/views/product-category/Index.vue";
import ProductCategoryList from "@/views/product-category/List.vue";
import ProductCategoryItem from "@/views/product-category/Item.vue";
import ProductBadgeList from "@/views/product-badge/List.vue";
import ProductBadgeItem from "@/views/product-badge/Item.vue";
import ProductAttributeIndex from "@/views/product-attribute/Index.vue";
import ProductAttributeList from "@/views/product-attribute/List.vue";
import ProductAttributeItem from "@/views/product-attribute/Item.vue";
import ProductChargesList from "@/views/product-charges/List.vue";
import ProductChargesItem from "@/views/product-charges/Item.vue";

import ProductReviewsList from "@/views/product-reviews/List.vue";
import ProductReviewsItem from "@/views/product-reviews/Item.vue";

import NotificationsIndex from "@/views/notifications/index.vue";

// Coupons
import CouponsList from "@/views/settings/coupons/List.vue";
import CouponsItem from "@/views/settings/coupons/Item.vue";

import About from "@/views/about/index.vue";
import ExportHistory from "@/views/settings/export-history/index.vue";

// Open Routes coming from Extension
import Redirect from "components/src/redirect.vue";
// Banners

import BannersList from "@/views/settings/banners/List.vue";
import BannersItem from "@/views/settings/banners/Item.vue";

import ContactsIndex from "components/src/contacts/Index.vue";

// Menu
import Menus from "@/views/settings/menu/List.vue";
import Menu from "@/views/settings/menu/Item.vue";

import NumbersList from "@/views/settings/numbers/List.vue";
import NumbersItem from "@/views/settings/numbers/Item.vue";

import LinksList from "@/views/settings/links/List.vue";
import LinksItem from "@/views/settings/links/Item.vue";

// Social Links
import SocialLinksList from "@/views/settings/social-links/List.vue";
import SocialLinksItem from "@/views/settings/social-links/Item.vue";

import PagesList from "@/views/settings/pages/List.vue";
import PagesItem from "@/views/settings/pages/Item.vue";

// THeme
import ThemeItem from "@/views/settings/theme/Item.vue";

import contacts from "./contact.js";
import BizSetup from "@/views/onboard/biz-setup.vue";
import Welcome from "@/views/onboard/welcome.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/get-started",
    name: "get-started",
    component: BizSetup,
  },
  {
    path: "/welcome",
    name: "welcome",
    component: Welcome,
  },
  {
    path: "/",
    component: Hydrate,
    children: [
      ...products,
      ...order,
      ...inventory,
      ...settings,
      {
        path: "",
        name: "home",
        component: Dashboard,
        meta: {
          label: "Dashboard",
          breadcrumbs: ["home"],
        },
      },
      {
        path: "notifications",
        name: "notifications",
        component: NotificationsIndex,
        meta: {
          label: "Notifications",
          breadcrumbs: ["home", "notifications"],
        },
      },
      {
        path: "contacts",
        component: ContactsIndex,
        children: contacts,
        meta: {
          label: "Customers",
          breadcrumbs: ["home", "contacts"],
        },
      },
      {
        path: "categories",
        component: ProductCategoryIndex,
        children: [
          {
            path: "",
            name: "product-categories",
            component: ProductCategoryList,
            props: true,
            meta: {
              label: "Product Categories",
              breadcrumbs: ["home", "product-categories"],
            },
          },
          {
            path: ":id",
            name: "product-category",
            component: ProductCategoryItem,
            props: true,
            meta: {
              label: "Product Category",
              breadcrumbs: ["home", "product-categories", "product-category"],
            },
          },
        ],
      },

      {
        path: "badges",
        name: "product-badges",
        component: ProductBadgeList,
        meta: {
          label: "Product Badges",
          breadcrumbs: ["home", "product-badges"],
        },
      },
      {
        path: "badges/:id",
        name: "product-badge",
        component: ProductBadgeItem,
        props: true,
        meta: {
          label: "Product Badge",
          breadcrumbs: ["home", "product-badges", "product-badge"],
        },
      },
      {
        path: "attributes",
        component: ProductAttributeIndex,
        children: [
          {
            path: "",
            name: "product-attributes",
            component: ProductAttributeList,
            meta: {
              label: "Product Attributes",
              breadcrumbs: ["home", "product-attributes"],
            },
          },
          {
            path: ":id",
            name: "product-attribute",
            component: ProductAttributeItem,
            props: true,
            meta: {
              label: "Product Attribute",
              breadcrumbs: ["home", "product-attributes", "product-attribute"],
            },
          },
        ],
      },
      {
        path: "charges",
        name: "product-charges",
        component: ProductChargesList,
        meta: {
          label: "Product Charges",
          breadcrumbs: ["home", "product-charges"],
        },
      },
      {
        path: "charges/:id",
        name: "product-charge",
        component: ProductChargesItem,
        props: true,
        meta: {
          label: "Product Charge",
          breadcrumbs: ["home", "product-charges", "product-charge"],
        },
      },

      {
        path: "reviews",
        name: "product-reviews",
        component: ProductReviewsList,
        meta: {
          label: "Product Reviews",
          breadcrumbs: ["home", "product-reviews"],
        },
      },
      {
        path: "reviews/:id",
        name: "product-review",
        props: true,
        component: ProductReviewsItem,
        meta: {
          label: "Product Review",
          breadcrumbs: ["home", "product-reviews", "product-review"],
        },
      },

      ...order,
      {
        path: "export-history",
        name: "export-history",
        component: ExportHistory,
        props: true,
        meta: {
          label: "Export History",
          breadcrumbs: ["home", "export-history"],
        },
      },

      {
        path: "coupons",
        name: "coupons",
        component: CouponsList,
        meta: {
          label: "Coupons",
          breadcrumbs: ["home", "coupons"],
        },
      },
      {
        path: "coupons/:id",
        name: "coupon",
        props: true,
        component: CouponsItem,
        meta: {
          label: "Coupon",
          breadcrumbs: ["home", "coupons", "coupon"],
        },
      },
      {
        path: "about",
        name: "about",
        component: About,
        meta: {
          label: "About",
          breadcrumbs: ["home", "about"],
        },
      },
      {
        path: "banners",
        name: "banners",
        component: BannersList,
        meta: {
          label: "Banners",
          breadcrumbs: ["home", "banners"],
        },
      },
      {
        path: "banners/:id",
        name: "banner",
        props: true,
        component: BannersItem,
        meta: {
          label: "Banner",
          breadcrumbs: ["home", "banners", "banner"],
        },
      },

      {
        path: "menus",
        name: "menus",
        component: Menus,
        meta: {
          label: "Menus",
          breadcrumbs: ["home", "menus"],
        },
      },
      {
        path: "menus/:id",
        name: "menu",
        component: Menu,
        props: true,
        meta: {
          label: "Menu",
          breadcrumbs: ["home", "menus", "menu"],
        },
      },

      {
        path: "contact-numbers",
        name: "numbers",
        component: NumbersList,
        meta: {
          label: "Contact Numbers",
          breadcrumbs: ["home", "numbers"],
        },
      },
      {
        path: "contact-numbers/:id",
        name: "number",
        component: NumbersItem,
        props: true,
        meta: {
          label: "Contact Number",
          breadcrumbs: ["home", "numbers", "number"],
        },
      },

      {
        path: "links",
        name: "links",
        component: LinksList,
        meta: {
          label: "Links",
          breadcrumbs: ["home", "links"],
        },
      },
      {
        path: "links/:id",
        name: "link",
        component: LinksItem,
        props: true,
        meta: {
          label: "Link",
          breadcrumbs: ["home", "links", "link"],
        },
      },

      {
        path: "social-links",
        name: "social-links",
        component: SocialLinksList,
        meta: {
          label: "Social Links",
          breadcrumbs: ["home", "social-links"],
        },
      },
      {
        path: "social-links/:id",
        name: "social-link",
        component: SocialLinksItem,
        props: true,
        meta: {
          label: "Social Links",
          breadcrumbs: ["home", "social-links", "social-link"],
        },
      },

      {
        path: "pages",
        name: "pages",
        component: PagesList,
        meta: {
          label: "Pages",
          breadcrumbs: ["home", "pages"],
        },
      },
      {
        path: "pages/:id",
        name: "page",
        component: PagesItem,
        props: true,
        meta: {
          label: "Page",
          breadcrumbs: ["home", "pages", "page"],
        },
      },
      {
        path: "theme",
        component: ThemeItem,
        name: "theme",
        props: true,
        meta: {
          label: "Theme",
          breadcrumbs: ["home", "theme"],
        },
      },
    ],
  },

  {
    path: "/redirect",
    name: "redirect",
    component: Redirect,
    meta: {
      public: true,
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "hash",
});

/**========================================================================
 * *                              NOTE
 * We don't allow users from mobile devices hence, the first thing before
 * entering the route should be to check if it is mobile OS
 *========================================================================**/
router.beforeEach(async (from, to, next) => {
  // Download page pending
  // if (
  //   ["android", "ios"].includes(os) &&
  //   from.name != "download" &&
  //   from.name != "verify-account"
  // ) {
  //   next({
  //     name: "download",
  //   });
  // } else {
  if (from.params.id && from.params.id !== "+") {
    from.params.id = parseInt(from.params.id);
  }

  if (from.meta.public === true) {
    next();
  } else {
    if (getToken()) {
      next();
    } else {
      window.open(VITE_WEB_URL, "_self");
    }
  }
});

router.afterEach((to, from) => {
  if (router.app.isSubscriptionActive) {
    pageView(to, from);
  }

  localStorage.setItem("vp-last-path", to.path);
});

export default router;
