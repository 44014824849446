<template>
  <VpProFeature title="Inventory" feature="INVENTORY_BULK_UPLOAD">
    <ProcessExcelHistory module="inventory" feature="INVENTORY_BULK_UPLOAD" />
  </VpProFeature>
</template>

<script>
import ProcessExcelHistory from "components/src/process-excel/history.vue";

export default {
  components: {
    ProcessExcelHistory,
  },
};
</script>
