<template>
  <VpField>
    <div class="vp-flex vp-items-stretch">
      <VyButton
        label="Select File"
        class="button--gray button--muted button--md vp-rounded-l"
        @click.native="openFilePicker"
      />
      <div
        class="vp-relative vp-bg-gray-200 vp-rounded-r vp-flex-grow vp-flex vp-items-center"
      >
        <div class="vp-w-px vp-bg-gray-300 vp-h-6"></div>
        <p class="vp-ml-3" :class="{ 'vp-text-gray-500': !fileName }">
          {{ fileName || "No file selected" }}
        </p>
        <input
          id="file"
          class="vp-absolute vp-inset-0 vp-cursor-pointer vp-opacity-0 vp-leading-9"
          type="file"
          @click="resetFile"
          @input="input"
        />
      </div>
    </div>
  </VpField>
</template>

<script>
export default {
  props: {
    value: File,
  },
  data() {
    return {
      fileName: null,
    };
  },

  methods: {
    input(e) {
      const file = e.target.files[0];
      this.fileName = file.name;
      this.$emit("input", file);
    },

    openFilePicker() {
      this.resetFile();
      document.querySelector(`#file`).click();
    },

    resetFile() {
      document.querySelector(`#file`).value = null;
      this.fileName = null;
    },
  },
};
</script>
