<template>
  <VpList
    :per-page="10"
    :columns="columns"
    endpoint="stockHistory"
    :request-payload="{ query: $options.stockHistory }"
    cache="stockHistory"
    :params="{ productId: Number(productId) }"
    class="vp-h-full"
    ref="list"
    title="Stock History"
    :search="false"
  >
    <template #order="{ item }">
      <VyButton
        v-if="item.order"
        :label="`#${item.order.orderId}`"
        :to="{ name: 'order', params: { id: item.order.id } }"
        class="button--primary"
      />
      <p v-else class="vp-text-gray-500">N/A</p>
    </template>
    <template #stock="{ item }">
      <VpProductStock
        :value="item.stock"
        :is-out-of-stock="item.isOutOfStock"
      />
    </template>
    <template #direction="{ item }">
      <VyButton
        badge
        :label="item.stock < 0 ? 'Out' : 'In'"
        class="button--pill button--muted button--sm"
        :class="item.stock < 0 ? 'button--warning' : 'button--success'"
      />
    </template>

    <template #createdAt="{ item }">
      <VpDatetime
        class="vp-text-gray-500 vp-text-sm"
        :value="item.order ? item.order.placedAt : item.addedAt"
      />
    </template>
  </VpList>
</template>

<script>
import stockHistory from "@/views/product/stock/history.gql";

export default {
  stockHistory,
  props: {
    productId: [Number, String],
  },
  computed: {
    columns() {
      return [
        { name: "direction", type: "string", fix: true },
        { name: "stock", label: "Change in Stock", type: "string" },
        { name: "order", type: "string" },
        { name: "createdAt", type: "string" },
      ];
    },
  },
};
</script>
