<template>
  <VpListLayout :aside="true">
    <template #left>
      <VpSubNav class="vp-p-4">
        <VpSubNavItem
          :to="{ name: 'product-inventory-bulk-history' }"
          label="History"
          align="left"
          fluid
        />
        <VpSubNavItem
          :to="{ name: 'product-inventory-bulk-update' }"
          label="Bulk Update"
          align="left"
          fluid
        />
      </VpSubNav>
    </template>

    <router-view />
  </VpListLayout>
</template>

<script>
export default {};
</script>
