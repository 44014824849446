<template>
  <VpListLayout add-route="product-badge" feature="BADGE">
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="product-badge-tags"
      :request-payload="{ query: $options.tags }"
      cache="tags"
      :filters.sync="filters"
      :params="params"
      class="vp-h-full"
      ref="list"
      title="Badges"
      reorder
      @rowClick="
        $router.push({
          name: 'product-badge',
          params: {
            id: $event.id,
          },
        })
      "
      @reorder="
        reorder('tags', $event, {
          type: 4,
        })
      "
      feature="BADGE"
    >
      <template #empty>
        <VpFeature the-key="BADGE" #default="{ feature }">
          <VpListEmpty :feature="feature" route="product-badge" />
        </VpFeature>
      </template>

      <template #filters>
        <VpField label="Status">
          <VpInput>
            <VpSelect
              v-model="filters.status"
              :options="statusOption"
              placeholder="All"
            />
          </VpInput>
        </VpField>
      </template>

      <template #status="{ item }">
        <VpStatus :value="item.status" />
      </template>
      <template #color="{ item }">
        <ColorPicker :value="item.color" readonly />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import ColorPicker from "components/src/color/picker.vue";
import reorder from "mixins/reorder.js";
import tags from "graph/tag/collection.gql";

export default {
  tags,
  mixins: [reorder],
  components: {
    ColorPicker,
  },
  data() {
    return {
      filters: {
        status: "",
      },
    };
  },
  computed: {
    params() {
      return { type: 4 };
    },
    statusOption() {
      return [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
      ];
    },
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "color", label: "Color", fix: true },
        { name: "name", type: "string" },
        {
          name: "productCount",
          label: "Products",
          fix: true,
          type: "string",
          value: (item) => item.tagStatistics?.productCount,
        },
        {
          name: "activeProductCount",
          label: "Active Products",
          type: "string",
          value: (item) => item.tagStatistics?.activeProductCount,
        },
        {
          name: "inactiveProductCount",
          label: "Inactive Products",
          type: "string",
          value: (item) => item.tagStatistics?.inactiveProductCount,
        },
        {
          name: "outOfStockProductCount",
          label: "Out Of Stock Products",
          type: "string",
          value: (item) => item.tagStatistics?.outOfStockProductCount,
        },
        { name: "status", fix: true },
      ];
    },
  },
};
</script>
