<template>
  <Reviews
    :contact-id="$route.params.id"
    filter-id="contact-reviews"
    :request-payload="{ noFilterStorage: true }"
  />
</template>

<script>
import Reviews from "../reviews/index.vue";

export default {
  components: {
    Reviews,
  },
};
</script>
