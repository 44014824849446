<template>
  <VpModal
    name="product-attribute-option"
    :title="id ? 'Edit Option' : 'Add Option '"
    _class="vp-w-1/3"
  >
    <div class="vp-p-6">
      <VpVueForm
        :fields="fields"
        :id="id"
        class="vp-p-0"
        :save="save"
        :get="get"
        :del="del"
        #default="{ state }"
        :track="$options.track"
        :has-reset="false"
        track-prefix="Attribute Option"
        feature="PRODUCT_ATTRIBUTE"
      >
        <!-- Color -->
        <template v-if="uiElement == 'color-palette'">
          <VpField rules="required" label="Color" name="Color">
            <ColorCodePicker
              :value="state.value"
              @input="
                state.value = $event.value;
                state.label = $event.name;
              "
            />
          </VpField>
          <VpField rules="required" label="Color Name" name="Color Name">
            <VpInput>
              <VpTextBox v-model="state.label" />
            </VpInput>
          </VpField>
        </template>

        <!-- Others -->
        <template v-else>
          <VpField rules="required" label="Value" name="Value">
            <VpInput>
              <VpTextBox v-model="state.value" />
            </VpInput>
          </VpField>
        </template>
      </VpVueForm>
    </div>
  </VpModal>
</template>

<script>
import get from "graph/productAttributeOption/get.gql";
import upsert from "graph/productAttributeOption/upsert.gql";
import del from "graph/productAttributeOption/delete.gql";
import ColorCodePicker from "@/components/color/code-picker.vue";

export default {
  track: {
    id: "Id",
    label: "Name",
    value: "Color Value",
  },
  props: {
    id: Number,
    attributeId: Number,
    uiElement: String,
    feature: Object,
  },
  components: {
    ColorCodePicker,
  },

  computed: {
    fields() {
      if (this.uiElement == "color-palette") {
        return [
          { name: "value", value: "#EF4444" },
          { name: "label", value: "Red" },
        ];
      } else {
        return ["value"];
      }
    },
  },

  methods: {
    get(id) {
      return this.$query(get, {
        id: id,
        attributeId: this.attributeId,
      }).then(({ data }) => {
        return {
          values: data.productAttributeOption,
          res: data,
        };
      });
    },

    save(id, data) {
      if (this.uiElement != "color-palette") {
        data.label = data.value;
      }

      return this.$mutate(upsert, {
        id,
        attributeId: this.attributeId,
        input: data,
      }).then(({ data }) => {
        if (this.feature) this.feature.refresh(true);
        this.$vayu.modal.close("product-attribute-option");
        this.$emit("refresh");
        return data;
      });
    },

    del(id) {
      return this.$mutate(del, {
        attributeId: this.attributeId,
        id,
      }).then(({ data }) => {
        this.feature.refresh(true);
        this.$vayu.modal.close("product-attribute-option");
        this.$emit("refresh");
        return data;
      });
    },
  },
};
</script>
