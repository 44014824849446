<template>
  <VpListLayout class="vp-h-full">
    <!-- LIST -->
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="orders"
      :request-payload="{ query: $options.orders }"
      :filters.sync="filters"
      cache="orders"
      class="vp-h-full"
      ref="list"
      title="Orders"
      bulk
      @rowClick="$router.push(link($event))"
      export-endpoint="order"
      export-feature="ORDER_EXPORT"
      export-history-route="export-history"
      feature="ORDER"
    >
      <!-- FILTERS -->
      <template #filters>
        <VpField label="Order Date">
          <VpDateRangePicker
            stack
            label="Order Date"
            :value="[
              filters.duration && filters.duration.start,
              filters.duration && filters.duration.end,
            ]"
            @input="
              filters.duration = {
                start:
                  $event[0] ?? String(Math.floor(new Date().getTime() / 1000)),
                end:
                  $event[1] ?? String(Math.floor(new Date().getTime() / 1000)),
                preset: 'custom',
              }
            "
            :max="null"
          />
        </VpField>

        <VpField label="Contact Name">
          <VpInput>
            <VpTextBox v-model="filters.customerName" />
          </VpInput>
        </VpField>

        <VpField label="Mobile No">
          <VpInput>
            <VpTextBox type="number" v-model="filters.mobileNo" />
          </VpInput>
        </VpField>

        <VpField label="Order ID">
          <VpInput>
            <VpTextBox v-model="filters.orderId" />
          </VpInput>
        </VpField>

        <VpField label="Products">
          <ProductSelector v-model="filters.productId" />
        </VpField>

        <VpField label="Category">
          <VpInput>
            <VpSelect
              v-model.number="filters.categoryId"
              :options="categoryOptions"
            />
          </VpInput>
        </VpField>

        <VpField label="Order Status">
          <OrderStatusInput v-model="filters.orderStatusId" />
        </VpField>

        <VpField label="Payment Status">
          <PaymentStatusInput v-model.number="filters.paymentStatusId" />
        </VpField>

        <VpField label="Payment Method">
          <PaymentModesInput v-model="filters.paymentModeId" />
        </VpField>

        <VpField label="Order By">
          <VpRadioGroup
            class="vp-flex"
            name="orderBy"
            :options="orderByOptions"
            v-model="filters.orderBy"
          />
        </VpField>
      </template>

      <!-- FIELDS -->
      <template #customer="{ item }">
        <VpConnection type="contact" :data="item.customer" />
      </template>
      <template #total="{ item }">
        <VpAmount class="vp-font-bold" :value="item.total" />
      </template>
      <template #orderStatus="{ item }">
        <OrderStatus :value="item.orderStatus && item.orderStatus.id" />
      </template>
      <template #paymentStatus="{ item }">
        <PaymentStatus :value="item.paymentStatus && item.paymentStatus.id" />
      </template>
      <template #placedAt="{ item }">
        <!-- Rerender after refresh button is pressed to see latest timestamp. 1 minute ago should be 3 minutes ago after refresh -->
        <VpDatetime
          class="vp-text-gray-500"
          :value="item.placedAt"
          :key="new Date().getSeconds()"
        />
      </template>
      <template #orderBy="{ item }">
        <span class="vp-capitalize">{{ item.orderBy }}</span>
      </template>

      <!-- BULK -->
      <template #_bulk="{ count, ids }">
        <VpBulkAction
          label="Delete Orders"
          :icon="$options.icons.Delete"
          @click="
            checkPermission('ORDER', 'delete', () => {
              deleteOrders(ids, count);
            })
          "
        />
        <BulkOrderStatus
          :ids="ids"
          :count="count"
          @refresh="$refs.list.refresh()"
        />
        <BulkPaymentStatus
          :ids="ids"
          :count="count"
          @refresh="$refs.list.refresh()"
        />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import { mapGetters } from "vuex";
import { productCategoryOptions } from "utils/presets";
import del from "../batch/delete.gql";
import ProductSelector from "components/src/product/search/index.vue";
import BulkPaymentStatus from "components/src/contacts/_PaymentStatus.vue";
import BulkOrderStatus from "components/src/contacts/_OrderStatus.vue";
import { Delete } from "icons/icons.js";
import OrderStatus from "components/src/order-status/display.vue";
import PaymentStatus from "components/src/payment-status/display.vue";
import PaymentModesInput from "components/src/payment-modes/input.vue";
import OrderStatusInput from "components/src/order-status/input.vue";
import PaymentStatusInput from "components/src/payment-status/input.vue";
import orders from "@/views/orders/collection.gql";

export default {
  orders,
  icons: {
    Delete,
  },
  props: {
    id: [Number, String],
    listParams: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ProductSelector,
    PaymentStatus,
    BulkPaymentStatus,
    OrderStatus,
    BulkOrderStatus,
    PaymentModesInput,
    OrderStatusInput,
    PaymentStatusInput,
  },
  data() {
    return {
      categoryOptions: [],

      filters: {
        customerName: null,
        mobileNo: null,
        orderId: null,
        categoryId: null,
        productId: null,
        orderStatusId: null,
        paymentModeId: null,
        paymentStatusId: null,
        orderBy: null,
        duration: null,
      },
      orderByOptions: [
        {
          label: "All",
          value: null,
        },
        {
          label: "Agent",
          value: "agent",
        },
        {
          label: "User",
          value: "user",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),

    columns() {
      return [
        { name: "orderId", label: "ID", fix: true, type: "string" },
        { name: "customer", label: "Customer", type: "string" },
        {
          name: "total",
          type: "number",
        },
        { name: "orderBy", label: "Order By", type: "string" },
        {
          name: "orderStatus",
          label: "Status",
          fix: true,
          type: "string",
        },
        { name: "paymentStatus", label: "Payment", fix: true, type: "string" },
        {
          name: "orderPaymentMode",
          label: "Paid Via",
          value: (item) =>
            item.orderPaymentMode?.displayName || item.orderPaymentMode?.name,
          type: "string",
        },
        {
          name: "ship_to",
          label: "Ship To",
          value: (row) => row.shipping?.name,
          type: "string",
        },
        { name: "placedAt", label: "Placed", fix: true, type: "date" },
      ];
    },
  },
  async created() {
    await this.getProductCategory();
  },
  methods: {
    link(order) {
      return {
        name: "order",
        params: {
          id: order.id,
        },
      };
    },

    async getProductCategory() {
      this.categoryOptions = await productCategoryOptions();
    },

    deleteOrders(ids, count) {
      const isConfirm = confirm(
        `Are you sure you want to delete ${count} orders?`
      );
      if (isConfirm) {
        this.$mutate(del, {
          id: ids,
        }).then(() => {
          this.$track("Bulk Action Order Deleted", {
            "Number Of Order Deleted": ids.length,
          });
          this.$refs.list.refresh();
        });
      }
    },
  },
};
</script>
