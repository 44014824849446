<template>
  <ul class="vp-list-none vp-flex vp-space-x-1 vp-flex-nowrap">
    <li v-for="(item, index) in data" :key="`attr-${index}`">
      <ProductAttribute :attribute="item.attribute" :option="item.option" />
    </li>
  </ul>
</template>

<script>
import ProductAttribute from "components/src/product-attribute.vue";

export default {
  props: {
    data: Array,
  },
  components: {
    ProductAttribute,
  },
};
</script>
