<template>
  <VpListLayout class="vp-bg-gray-50 vp-w-full vp-overflow-auto">
    <template #header-start>
      <VpButtonGraySmMuted
        :icon="$options.icons.CheckAll"
        label="Mark all as read"
        @click.native="markAsAllRead"
      />

      <VpButtonGraySmMuted
        :icon="$options.icons.Refresh"
        label="Refresh"
        @click.native="$refs.list.refresh()"
      />

      <VpButtonGraySmMuted
        :icon="$options.icons.Delete"
        label="Clear all"
        @click.native="checkPermission('NOTIFICATION', 'delete', clearAll)"
      />
    </template>

    <VpList
      :per-page="10"
      :columns="[]"
      endpoint="notifications"
      :request-payload="{ query: $options.notifications }"
      :params="params"
      :filters.sync="localFilters"
      cache="notifications"
      ref="list"
      title="Notifications"
      :header="false"
      :infinite="true"
    >
      <template #default="{ items }">
        <div
          class="vp-my-6 vp-mx-auto vp-divide-y vp-w-2/3 vp-rounded-lg vp-overflow-hidden vp-border vp-bg-white"
        >
          <VpNotificationCard
            v-for="(item, index) in items"
            :key="`notification-${index}`"
            :id="item.id"
            :is-read="item.readDateTime ? true : false"
            :title="item.title"
            :description="item.description"
            :type="item.feature?.key"
            :created-at="item.createdAt"
            buttonLabel="Mark as read"
            @redirect="
              goTo(
                item.id,
                item.readDateTime ? true : false,
                item.redirectionUrl,
                item
              )
            "
            @mark-as-read="markAsRead"
          />
        </div>
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import readAll from "./read-all.gql";
import readOne from "./read-one.gql";
import deleteNotifications from "./delete.gql";
import { mapGetters } from "vuex";
import { Refresh, Delete, CheckAll } from "icons/icons.js";
import notifications from "./collection.gql";

export default {
  notifications,
  props: {
    filters: {
      type: Object,
    },
  },
  icons: {
    Refresh,
    Delete,
    CheckAll,
  },
  computed: {
    ...mapGetters({
      userId: "user/id",
      checkPermission: "user/checkPermission",
    }),

    params() {
      return {
        userId: this.userId,
      };
    },

    localFilters() {
      const type = this.$route.query?.type;
      return {
        readAt: type && type === "all" ? null : false,
        ...this.filters,
      };
    },
  },
  methods: {
    markAsRead({ id }) {
      return this.$mutate(readOne, {
        ...this.params,
        id,
      }).then(({ data: { updateNotification } }) => {
        this.reloadList(updateNotification);
      });
    },

    markAsAllRead() {
      return this.$mutate(readAll, this.params).then(() => {
        this.reloadList();
      });
    },

    clearAll() {
      const isConfirm = confirm(
        "Are you sure you want to delete all notifications?"
      );
      if (isConfirm) {
        return this.$mutate(deleteNotifications).then(() => {
          this.reloadList();
        });
      } else {
        return;
      }
    },

    reloadList(updateNotificationCount = 0) {
      this.$root.$emit("NOTIFICATIONS:SET", updateNotificationCount);
      this.$refs.list.refresh();
    },

    goTo(id, isRead, redirectionUrl, notification) {
      const featureKey = notification.feature;
      if (featureKey && featureKey.key) {
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "notifications",
        });
        const route = redirectionUrl.split("#")[1];
        this.$router.push(`${route}${isRead ? "" : `?nId=${id}`}`);
      } else {
        window.open(redirectionUrl, "_blank");
      }
    },
  },
};
</script>
