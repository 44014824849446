<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    :save="save"
    :get="get"
    class="vp-p-6"
    :del="del"
    width="400px"
    #default="{ state }"
    :track="$options.track"
    track-prefix="Badge"
    collection-cache="tags"
    feature="BADGE"
  >
    <VpField rules="required" label="Badge" name="Badge">
      <VpInput>
        <VpTextBox v-model="state.name" />
      </VpInput>
    </VpField>

    <VpField label="Color" name="Color" rules="required">
      <ColorPicker v-model="state.color" />
    </VpField>

    <VpStatusInput v-model="state.status" />
  </VpVueForm>
</template>

<script>
import ColorPicker from "components/src/color/picker.vue";

//Product Badges are nothing but a tag;
import move from "graph/tag/delete.gql";
import get from "graph/tag/get.gql";
import upsert from "graph/tag/upsert.gql";

export default {
  track: {
    id: "Id",
    name: "Name",
    color: "Color",
    status: "Status",
  },
  props: {
    id: [Number, String],
  },
  components: {
    ColorPicker,
  },
  data() {
    return {
      fields: [
        "id",
        "name",
        { name: "color", value: "red" },
        { name: "type", value: 4 },
        { name: "status", value: "active" },
      ],
    };
  },
  methods: {
    get(id) {
      return this.$query(get, {
        id: id,
      }).then(({ data }) => {
        return {
          values: data.tag,
          res: data,
        };
      });
    },

    save(id, data) {
      return this.$mutate(upsert, {
        input: {
          id,
          ...data,
        },
      }).then(({ data }) => {
        this.$router.push({ name: "product-badges" });
        return data;
      });
    },

    del(id, action) {
      return this.$mutate(move, {
        id: parseInt(id),
        action,
      }).then(({ data }) => {
        this.$router.push({ name: "product-badges" });

        return data;
      });
    },
  },
};
</script>
