<template>
  <VpLoaderContainer class="vp-py-12" v-if="isLoading" loading />

  <div v-else-if="tieredPricingList.length > 0" class="vp-p-4 vp-divide-y">
    <ValidationObserver tag="div" ref="formObserver">
      <ValidationProvider slim tag="div" :name="`field`">
        <VyTable
          class="vp-w-full table--relaxed table--border-y table--border-edge"
        >
          <template #default>
            <tr>
              <th class="vp-text-start">Tier</th>
              <th class="vp-text-start">
                Regular Price -
                <span class="vp-text-gray-400 vp-italic vp-font-normal"
                  >Optional</span
                >
              </th>
              <th class="vp-text-start">Selling Price</th>
              <th class="vp-text-start">Discount</th>
            </tr>
            <tr v-for="(tierPricing, index) in tieredPricingList" :key="index">
              <td class="vp-w-1/4">
                <p>
                  {{ tierPricing.tier.name }}
                </p>
              </td>
              <td>
                <VpField
                  vid="regularPrice"
                  optional
                  :name="`Regular Price of ${tierPricing.tier.name}`"
                  :key="tierPricing.tier.name"
                  rules="positiveNonZeroFloat"
                >
                  <VpInput :after="currency">
                    <VpTextBox
                      type="number"
                      step="0.01"
                      v-model.number="tierPricing.regularPrice"
                      @input="
                        $emit(
                          'update:tierPricing.regularPrice',
                          parseFloat($event)
                        )
                      "
                    />
                  </VpInput>
                </VpField>
              </td>
              <td>
                <VpField
                  :rules="priceRule(tierPricing.regularPrice)"
                  :name="`Selling Price of ${tierPricing.tier.name}`"
                  :key="tierPricing.tier.name"
                  vid="sellingPrice"
                >
                  <VpInput :after="currency">
                    <VpTextBox
                      type="number"
                      step="0.01"
                      v-model.number="tierPricing.price"
                      @input="
                        $emit(
                          'update:tierPricing.price',
                          isNaN(parseFloat($event)) ? null : parseFloat($event)
                        )
                      "
                    />
                  </VpInput>
                </VpField>
              </td>
              <td>
                <span
                  class="vp-text-warning-500"
                  v-if="discount(tierPricing.price, tierPricing.regularPrice)"
                  >{{
                    discount(tierPricing.price, tierPricing.regularPrice)
                  }}%</span
                >
              </td>
            </tr>
          </template>
        </VyTable>
      </ValidationProvider>
    </ValidationObserver>
    <VyButton
      label="Save"
      type="submit"
      @click.native="save"
      class="button--primary button--solid button--rounded button--right button--md vp-flex-none vp-mt-2"
    />
  </div>

  <VpErrorPage
    v-else
    title="No Tiers Created!"
    desc="To Add Tier, You need to visit CRM. Once you have setup CRM, you can add tiers by visiting Settings."
  >
    <template #actions>
      <VyButton
        label="Add New"
        :icon="$options.icons.Add"
        class="button--primary button--solid button--sm button--rounded"
        @click.native="isCrmProductSetup ? toTier() : toCrm()"
      />
    </template>
  </VpErrorPage>
</template>

<script>
import { mapGetters } from "vuex";

import get from "graph/product/get.gql";
import upsert from "graph/product/updateProductTieredPrices.gql";
import { getDiscountPrice, toTier, toCrm } from "@/utils";
import { Add } from "icons/icons.js";
export default {
  icons: {
    Add,
  },
  props: {
    productId: [Number, String],
  },

  data() {
    return {
      tieredPricingList: [],
      isLoading: false,
      pricingRule: "",
    };
  },

  mounted() {
    this.get();
  },
  computed: {
    ...mapGetters({
      currency: "store/currencySymbol",
      isCrmProductSetup: "biz/isCrmProductSetup",
    }),
  },
  methods: {
    toTier,
    toCrm,
    get() {
      this.isLoading = true;
      return this.$query(get, {
        id: Number(this.productId),
      })
        .then(({ data }) => {
          const product = { ...data.product };
          this.tieredPricingList = product.tieredPrices;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    priceRule(price) {
      if (!Number(price)) {
        return "required";
      } else if (price) {
        return `required|max_value:${price}`;
      }
    },

    discount(price, regularPrice) {
      return getDiscountPrice(price, regularPrice);
    },

    async save() {
      const isValid = await this.$refs.formObserver.validate();
      if (!isValid) {
        return;
      }
      const postData = this.tieredPricingList.map((res) => {
        return {
          tierId: res.tier.id,
          price: res.price || null,
          regularPrice: res.regularPrice || null,
        };
      });

      this.$mutate(upsert, {
        input: {
          tieredPrices: postData,
        },
        id: Number(this.productId),
      })
        .then(() => {
          this.$vayu.notify({
            message: "Tired pricing updated.",
            state: "success",
          });
        })
        .catch((err) => {
          console.error(err);
          this.$vayu.notify({
            message: err,
            state: "danger",
          });
        });
    },
  },
};
</script>
