<template>
  <div
    class="vp-h-5 vp-inline-flex vp-px-2 vp-items-center vp-rounded"
    :class="$options.colorClasses[color]"
  >
    <span class="vp-text-xs">{{ label }}</span>
  </div>
</template>

<script>
import colorClasses from "static/src/muted-button-color-classes.json";

export default {
  colorClasses,
  props: {
    label: String,
    color: String,
  },
};
</script>
