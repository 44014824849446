<template>
  <div class="vp-max-w-3xl">
    <div>
      <TheHeader
        title="Features of Vepaar Store"
        desc="Start selling online for free. You can use countless features, all of which help you manage your business easily."
      />

      <CardsShell>
        <!-- {Product} -->
        <TheCard
          icon-color="pink"
          title="Products"
          desc="Create any or all of the three product -simple, variable, and digital."
        >
          <template #icon>
            <VyIcon :name="$options.icons.Product" />
          </template>
        </TheCard>

        <TheCard
          icon-color="cyan"
          title="Categories"
          desc="Design your product catalog with as many categories as you wish."
        >
          <template #icon>
            <VyIcon :name="$options.icons.Category" />
          </template>
        </TheCard>

        <TheCard
          icon-color="warning"
          title="Badges"
          desc="Easily make customized badges so that a particular product can stand."
        >
          <template #icon>
            <VyIcon :name="$options.icons.Badge" />
          </template>
        </TheCard>

        <TheCard
          icon-color="danger"
          title="Charges"
          desc="Set up Taxes, Bulk Order Fees, Gift Wrapping or any other charges."
        >
          <template #icon>
            <VyIcon :name="$options.icons.Cash" />
          </template>
        </TheCard>

        <TheCard
          icon-color="blue"
          title="Inventory"
          desc="Easily manage inventory so that a particular product can be available."
        >
          <template #icon>
            <VyIcon :name="$options.icons.Inventory" />
          </template>
        </TheCard>

        <TheCard
          icon-color="success"
          title="Shipping"
          desc="Shipping details like address can easily be added."
        >
          <template #icon>
            <VyIcon :name="$options.icons.Shipping" />
          </template>
        </TheCard>
      </CardsShell>
    </div>
  </div>
</template>

<script>
import {
  Product,
  Inventory,
  Shipping,
  Badge,
  Cash,
  Category,
} from "icons/icons.js";
import TheCard from "components/src/features/_card.vue";
import TheHeader from "components/src/features/_header.vue";
import CardsShell from "components/src/features/_cards-shell.vue";
export default {
  components: {
    TheCard,
    TheHeader,
    CardsShell,
  },
  icons: {
    Category,
    Product,
    Cash,
    Inventory,
    Shipping,
    Badge,
  },
};
</script>
