import List from "@/views/inventory/List.vue";
import Index from "@/views/inventory/bulk/Index.vue";
import History from "@/views/inventory/bulk/History.vue";
import Update from "@/views/inventory/bulk/Update.vue";

export default [
  {
    path: "inventory",
    component: List,
    name: "product-inventory",
    meta: {
      label: "Product Inventory",
      breadcrumbs: ["home", "product-inventory"],
    },
  },
  {
    path: "inventory/bulk",
    component: Index,
    children: [
      {
        path: "",
        name: "product-inventory-bulk",
        redirect: "history",
        meta: {
          label: "Bulk Actions",
          breadcrumbs: ["home", "product-inventory", "product-inventory-bulk"],
        },
      },
      {
        path: "history",
        name: "product-inventory-bulk-history",
        component: History,
        meta: {
          label: "History",
          breadcrumbs: [
            "home",

            "product-inventory",
            "product-inventory-bulk",
            "product-inventory-bulk-history",
          ],
        },
      },
      {
        path: "update",
        name: "product-inventory-bulk-update",
        component: Update,
        meta: {
          label: "Update",
          breadcrumbs: [
            "home",
            "product-inventory",
            "product-inventory-bulk",
            "product-inventory-bulk-update",
          ],
        },
      },
    ],
  },
];
