<template>
  <VpModal
    name="product-default-attribute"
    title="Select Option for Existing Variants"
    _class="vp-w-1/3"
  >
    <VpRequest :get="get" #default="{ res }">
      <div class="vp-p-6">
        <p>
          When assigning a new attribute the the product, the existing variants
          needs to be updated with an option. Select the value to assign to
          existing variants.
        </p>

        <VpField :label="res.name" class="vp-mt-6">
          <ProductAttributeInput
            :ui-element="res.uiElement"
            :options="res.options"
            @input="select($event)"
          />
        </VpField>
      </div>
    </VpRequest>
  </VpModal>
</template>

<script>
import get from "graph/product/getAttributeWithOptions.gql";
import ProductAttributeInput from "components/src/product-attribute-input.vue";

export default {
  props: {
    id: Number,
  },
  components: {
    ProductAttributeInput,
  },
  methods: {
    get() {
      return this.$query(get, {
        id: this.id,
      }).then(({ data }) => {
        return data.productAttribute;
      });
    },

    select(optionId) {
      this.$emit("select", optionId);
      this.$vayu.modal.close("product-default-attribute");
    },
  },
};
</script>
