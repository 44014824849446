<template>
  <div class="vp-p-4">
    <VpVueForm
      :id="productId"
      :fields="fields"
      :get="get"
      :save="save"
      ref="item"
      width="400px"
      :form-state.sync="formState"
      collection-cache="product"
      cache="productStock"
    >
      <template #default="{ state }">
        <VpAlertCard
          v-if="!manageInventory"
          color="warning"
          emoji="⚠️"
          title="Manage Inventory is Disabled"
        >
          <p>
            To manage stock, enable Manage Inventory from
            <RouterLink
              :to="{ name: 'product', params: { productId } }"
              class="vp-underline"
              >here</RouterLink
            >
          </p>
        </VpAlertCard>

        <VpField v-if="manageInventory" label="SKU">
          <h4 class="vp-capitalize vp-text-lg vp-font-bold">
            {{ sku || "N/A" }}
          </h4>
        </VpField>

        <VpField label="Current Stock">
          <h4 class="vp-capitalize vp-text-lg vp-font-bold">
            {{ currentStock || "N/A" }}
          </h4>
        </VpField>

        <VpField
          v-if="manageInventory"
          label="Adjust Stock"
          name="Adjust Stock"
          rules="required"
          note="Use negative value to deduct the stock. E.g. -5"
        >
          <VpInput>
            <VpTextBox type="number" v-model="state.stock" step="1" />
          </VpInput>
        </VpField>

        <VpField
          v-if="manageInventory"
          label="Stock After Adjustment"
          name="Stock After Adjustment"
        >
          <h4 class="vp-capitalize vp-text-lg vp-font-bold">
            {{ stockAfterAdjustment(state.stock) }}
          </h4>
        </VpField>
      </template>

      <template #footer="{ isSaving }">
        <VyButton
          v-if="manageInventory"
          :loading="isSaving"
          label="Save"
          type="submit"
          class="button--solid button--primary button--md button--rounded"
        />
        <div v-else></div>
      </template>
    </VpVueForm>
  </div>
</template>

<script>
import get from "graph/product/stock/getSimple.gql";
import update from "graph/product/stock/update.gql";

export default {
  props: {
    productId: [Number, String],
    type: String,
    manageInventory: Boolean,
  },

  data() {
    return {
      currentStock: null,
      formState: null,
      sku: null,
      fields: ["stock"],
    };
  },

  methods: {
    stockAfterAdjustment(stock) {
      return parseInt(this.currentStock || 0) + parseInt(stock || 0);
    },
    get(id) {
      return this.$query(get, {
        id: Number(id),
      }).then(({ data }) => {
        this.currentStock = data.product.stock;
        this.sku = data.product.sku;
        return {
          values: {
            stock: null,
          },
          res: data,
        };
      });
    },

    async save(id, data) {
      const payload = {
        ...data,
        stock: Number(data.stock),
      };
      return this.$mutate(update, {
        productId: Number(id),
        input: payload,
      }).then(({ data }) => {
        this.$refs.item.refresh();
        this.$refs.item?.$refs.formObserver.reset();
        return data;
      });
    },
  },
};
</script>
