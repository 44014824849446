<template>
  <div class="vp-p-6">
    <div class="vp-flex vp-justify-end vp-items-center">
      <VpButtonSm
        label="Add Option"
        :icon="$options.icons.Add"
        class="vp-flex vp-justify-end vp-whitespace-nowrap vp-my-1 button--sm"
        @click.native="
          selectedOptionId = null;
          $vayu.modal.open('product-attribute-option');
        "
      />
    </div>

    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="productAttributeOptions"
      :request-payload="{ query: $options.productAttributeOptions }"
      cache="productAttributeOptions"
      :params="{ attributeId }"
      class="vp-h-full"
      ref="list"
      title="Product Attribute Options"
      :search="false"
      reorder
      @rowClick="
        selectedOptionId = $event.id;
        $vayu.modal.open('product-attribute-option');
      "
      @reorder="
        reorder('productAttributeOptions', $event, {
          attributeId,
        })
      "
      feature="PRODUCT_ATTRIBUTE"
    >
      <template v-if="uiElement == 'color-palette'" #value="{ item }">
        <color-code-picker readonly :value="item.value" />
      </template>

      <template #status="{ item }">
        <VpStatus :value="item.status" />
      </template>
    </VpList>

    <TheOption
      :id="selectedOptionId"
      :attribute-id="attributeId"
      :ui-element="uiElement"
      @refresh="$refs.list.refresh()"
      :feature="feature"
    />
  </div>
</template>

<script>
import reorder from "mixins/reorder.js";
import ColorCodePicker from "@/components/color/code-picker.vue";
import TheOption from "./_Option.vue";
import { Add } from "icons/icons.js";
import productAttributeOptions from "graph/productAttributeOption/collection.gql";

export default {
  productAttributeOptions,
  icons: {
    Add,
  },
  props: {
    attributeId: Number,
    uiElement: String,
    feature: Object,
  },
  mixins: [reorder],
  components: {
    ColorCodePicker,
    TheOption,
  },
  data() {
    return {
      selectedOptionId: null,
    };
  },
  computed: {
    columns() {
      if (this.uiElement == "color-palette") {
        return [
          { name: "_index", label: "#", fix: true },
          { name: "value", label: "Color", fix: true, type: "string" },
          { name: "label", label: "Color Name", type: "string" },
        ];
      } else {
        return [
          { name: "_index", label: "#", fix: true },
          { name: "value", type: "string" },
        ];
      }
    },
  },
};
</script>
