<template>
  <div>
    <button
      v-if="readonly"
      type="button"
      class="vp-w-6 vp-h-6 vp-rounded-full"
      :style="inlineStyle(value)"
    />
    <template v-else>
      <div class="vp-gap-1 vp-grid vp-grid-cols-[repeat(11,32px)]">
        <button
          v-for="color in colors"
          :key="color.name"
          :style="inlineStyle(color.value)"
          v-tooltip="color.name"
          @click="
            custom = false;
            $emit('input', color);
          "
          type="button"
          :class="{
            [$options.style.colorButton]: true,
            'vp-ring-1 vp-ring-inset vp-ring-gray-200': color.name == 'White',
          }"
        >
          <VpIcon
            v-if="value == color.value"
            :name="$options.icons.Check"
            class="vp-w-4 vp-h-4"
          />
        </button>

        <button
          type="button"
          @click="custom = true"
          class="vp-ring-1 vp-ring-inset vp-ring-gray-200"
          :class="{
            [$options.style.colorButton]: true,
          }"
        >
          <VpIcon
            class="vp-text-gray-500 vp-w-4 vp-h-4"
            :name="$options.icons.ColorPicker"
          />
        </button>
      </div>

      <chrome-color-picker
        v-if="custom"
        class="vp-mt-4 vp-border vp-border-gray-300 vp-rounded-md !vp-shadow-sm"
        :value="value"
        @input="
          $emit('input', {
            value: $event.hex,
            name: '',
          })
        "
        :preset-colors="[]"
      />
    </template>
  </div>
</template>
<script>
import { Check, ColorPicker } from "icons/icons.js";
import { Chrome } from "vue-color";

export default {
  style: {
    colorButton:
      "vp-w-8 vp-h-8 vp-rounded-full vp-flex vp-items-center vp-justify-center vp-text-white vp-cursor-pointer vp-border-none",
  },
  icons: {
    ColorPicker,
    Check,
  },
  props: {
    value: {
      type: String,
    },
    readonly: {
      type: Boolean,
    },
  },

  components: {
    ChromeColorPicker: Chrome,
  },

  created() {
    if (this.value) {
      this.custom = this.colors.find((item) => this.value == item.value)
        ? false
        : true;
    }
  },

  data() {
    return {
      custom: false,
      colors: [
        { name: "Red", value: "#EF4444" },
        { name: "Orange", value: "#F97316" },
        { name: "Amber", value: "#F59E0B" },
        { name: "Yellow", value: "#EAB308" },
        { name: "Lime", value: "#84CC16" },
        { name: "Green", value: "#22C55E" },
        { name: "Emerald", value: "#10B981" },
        { name: "Teal", value: "#14B8A6" },
        { name: "Cyan", value: "#06B6D4" },
        { name: "Light Blue", value: "#0EA5E9" },
        { name: "Blue", value: "#3B82F6" },
        { name: "Indigo", value: "#6366F1" },
        { name: "Violet", value: "#8B5CF6" },
        { name: "Purple", value: "#A855F7" },
        { name: "Fuchsia", value: "#D946EF" },
        { name: "Pink", value: "#EC4899" },
        { name: "Rose", value: "#F43F5E" },
        { name: "Brown", value: "#795548" },
        { name: "Gray", value: "#9E9E9E" },
        { name: "White", value: "#ffffff" },
        { name: "Black", value: "#000000" },
      ],
    };
  },
  methods: {
    inlineStyle(color) {
      return {
        background: color,
      };
    },
  },
};
</script>
