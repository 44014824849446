<template>
  <VpVueForm
    :id="fileId"
    :fields="fields"
    class="vp-p-6"
    :get="get"
    :save="save"
    :del="del"
    #default="{ state, response }"
    width="400px"
    ref="item"
    collection-cache="productContents"
    cache="productContent"
    feature="PRODUCT_CONTENT"
  >
    <VpField rules="required" label="Name" name="Name">
      <VpInput>
        <VpTextBox v-model="state.name" />
      </VpInput>
    </VpField>

    <VpField label="Description" name="Description" optional>
      <VpTextArea v-model="state.description" :rows="3" />
    </VpField>

    <VpField label="Upload Thumb" name="Upload Thumb" optional>
      <VpImageEditor id="THUMB" v-model="thumb" crop #default="{ value }">
        <VpMedia type="Image" size="96" :value="value" />
      </VpImageEditor>
    </VpField>

    <VpField label="How to add Content?" name="How to add Content?">
      <VpRadioGroup
        name="content"
        class="vp-flex"
        :value="state.isUrl ? 'url' : 'upload'"
        @input="state.isUrl = $event == 'url'"
        :options="[
          { label: 'External URL', value: 'url' },
          { label: 'Upload File', value: 'upload' },
        ]"
      />
    </VpField>

    <VpField
      v-if="state.isUrl"
      note="Customer will be redirected to this link while accessing this content. Link must start with http:// or https://"
      rules="required|url"
      label="URL"
      name="URL"
    >
      <VpInput>
        <VpTextBox v-model="state.url" placeholder="https://www.example.com" />
      </VpInput>
    </VpField>

    <VpField
      v-if="!state.isUrl && !state.fileId"
      rules="required"
      label="Upload File"
      name="Upload File"
      note="Customer will be able to download this file while accessing this content."
    >
      <FormFile v-model="newFile" :accept="$options.fileTypes" />
    </VpField>

    <VpField
      v-if="!state.isUrl && state.fileId && response"
      label="File"
      name="File"
    >
      <div class="vp-flex">
        <VyButton
          type="button"
          :icon="$options.icons.Download"
          label="Download"
          target="_blank"
          :href="response.file.url"
          class="button--primary button--md button--rounded button--muted"
        />
      </div>
    </VpField>
  </VpVueForm>
</template>

<script>
import { Download } from "icons/icons.js";
import { createMedia, deleteMedia } from "utils/presets";

import FormFile from "./_form-file.vue";
import del from "graph/product/content/delete.gql";
import get from "graph/product/content/get.gql";
import upsert from "graph/product/content/upsert.gql";
import { fileToBase64 } from "plugins/utils.js";

export default {
  icons: {
    Download,
  },
  fileTypes:
    ".jpg,.jpeg,.png,.pdf,.mp4,.mp3,.aac,.csv,.doc,.docx,.ppt,.xls,.xlsx,.rtf,.txt,.zip",
  props: {
    productId: [String, Number],
    fileId: [String, Number],
  },
  components: {
    FormFile,
  },
  data() {
    return {
      fields: [
        "name",
        "description",
        { name: "isUrl", value: false },
        "url",
        "fileId",
        "thumbId",
      ],
      thumb: {},
      newFile: null,
      loading: false,
    };
  },
  methods: {
    get(id) {
      return this.$query(get, {
        id: Number(id),
        productId: Number(this.productId),
      }).then(({ data }) => {
        const { productContent } = data;

        this.thumb = {
          url: productContent.thumb?.url,
        };

        productContent.thumbId = productContent?.thumb?.id;

        /**
         * If isUrl is true then we don't need to send fileId
         * URL is coming inside file object in response
         */
        if (productContent.isUrl) {
          productContent.url = productContent?.file?.url;
        } else {
          productContent.fileId = productContent?.file?.id;
        }
        return {
          values: productContent,
          res: data,
        };
      });
    },

    async save(id, data) {
      if (this.newFile && !data.isUrl) {
        /**
         * Converting file to base64 for createMedia mutation.
         */
        const { fileName, dataUrl } = await fileToBase64(this.newFile);
        data.url = null;
        try {
          data.fileId = await this.upload(dataUrl, fileName);
        } catch {
          return;
        }
      }

      if (this.thumb.status == "CHANGE") {
        const uploadedFile = await createMedia(
          "digital_product",
          [this.thumb.url],
          [this.thumb.name]
        );
        data.thumbId = uploadedFile[0].id;
      } else if (this.thumb.status == "DELETE") {
        await deleteMedia([data.thumbId]);
        data.thumbId = null;
      }

      if (data.isUrl) {
        data.fileId = null;
      }

      return this.$mutate(upsert, {
        productId: Number(this.productId),
        id: Number(id),
        input: data,
      }).then(({ data }) => {
        this.$refs.item.evictCache();
        this.$router.push({
          name: "product-content",
        });
        return data;
      });
    },

    del(id) {
      return this.$mutate(del, {
        productId: Number(this.productId),
        id: id ? Number(id) : null,
      }).then(() => {
        this.$router.push({
          name: "product-content",
        });
      });
    },

    async upload(dataUrl, fileName) {
      try {
        const uploadedFile = await createMedia(
          "digital_product",
          [dataUrl],
          [fileName]
        );
        return uploadedFile[0].id;
      } catch (err) {
        this.$vayu.notify({
          title: err.message,
          state: "danger",
        });

        throw new Error(err);
      }
    },
  },
};
</script>
