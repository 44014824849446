<template>
  <VpRequest key="create-variant" :get="get" #default="{ res }" ref="request">
    <div v-if="res.attributesCount == 0" class="vp-p-6">
      <AddAttributes class="vp-p-0" :product-id="productId" />
      <div class="vp-mt-6 vp-pt-6 vp-border-t">
        <VyButton
          label="Continue"
          :icon="$options.icons.ArrowRight"
          @click.native="$refs.request.refresh()"
          class="button--primary button--solid button--md button--rounded button--right"
        />
      </div>
    </div>

    <slot v-else />
  </VpRequest>
</template>

<script>
import attributes from "graph/productVariant/attributes.gql";
import AddAttributes from "../attributes/List.vue";
import { ArrowRight } from "icons/icons.js";

export default {
  icons: {
    ArrowRight,
  },
  props: {
    productId: [Number, String],
  },

  components: {
    AddAttributes,
  },

  methods: {
    get() {
      return this.$query(attributes, {
        id: Number(this.productId),
      }).then(({ data }) => {
        return {
          attributesCount: data.productAssignedAttributes.data.length,
        };
      });
    },
  },
};
</script>
