<template>
  <VpVueForm
    :fields="fields"
    :id="storeId"
    class="vp-p-6"
    :save="save"
    :get="get"
    #default="{ state }"
    width="400px"
    :track="$options.track"
    track-prefix="Store"
    feature="STORE_SETTINGS"
  >
    <template v-if="state.status == 'active'">
      <VpField optional label="Invoice Due After" name="Invoice Due After">
        <VpInput after="Days">
          <VpTextBox type="number" v-model="state.invoiceDueAfter" />
        </VpInput>
      </VpField>

      <VpField optional label="Receipt Notes" name="Receipt Notes">
        <VpTextArea
          :rows="5"
          v-model="state.invoiceNotes"
          placeholder="Enter Taxation and Banking Details as per your Country guidelines."
        />
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import { mapGetters } from "vuex";

import get from "@/graph/store.gql";
import upsert from "@/graph/biz-setup.gql";

export default {
  track: {
    name: "Name",
    currency: {
      key: "Currency",
      value: ({ currency }) => currency,
    },
    invoiceNotes: "Invoice Note",
    invoiceDueAfter: {
      key: "Invoice Due After",
      value: (invoiceDueAfter) => `${invoiceDueAfter} Days`,
    },
    status: "Status",
  },
  data() {
    return {
      loading: false,
      bizSetupDone: false,

      fields: [
        "name",
        "country",
        "currency",
        "invoiceNotes",
        { name: "invoiceDueAfter", value: 0 },
        { name: "status", value: "active" },
        "productPriceDisplay",
      ],
    };
  },
  computed: {
    ...mapGetters({
      storeId: "store/id",
    }),
  },
  methods: {
    get(id) {
      //For stores, we're getting an array of stores
      //If the store is set we'll always use the 1st store setup/
      //We're supporting multiple store but we're using only 1st for now
      return this.$query(get, {
        id,
      }).then(({ data }) => {
        const store = data.stores.data[0];
        if (store) {
          store.currency = store.currency.abbreviation;
        }
        return {
          values: store,
          res: {
            store,
          },
        };
      });
    },
    save(id, data) {
      const payload = {
        name: data.name,
        productPriceDisplay: data.productPriceDisplay,
        invoiceNotes: data.invoiceNotes,
        countryId: data.country && Number(data.country?.id),
        currency: data.currency,
        invoiceDueAfter:
          data.invoiceDueAfter === "" ||
          data.invoiceDueAfter === null ||
          data.invoiceDueAfter === 0 ||
          data.invoiceDueAfter === "0"
            ? null
            : Number(data.invoiceDueAfter),
      };
      return this.$mutate(upsert, {
        id,
        input: {
          ...payload,
        },
      }).then(({ data }) => {
        this.$root.$emit("rehydrate", null, true);
        return data;
      });
    },
  },
};
</script>
