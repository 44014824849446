<template>
  <VpListLayout
    :add-route="{ name: 'product-item-variant-bulk' }"
    addLabel="Create Variants"
    feature="PRODUCT_VARIANT"
  >
    <template #header-start>
      <VpPortalButton
        label="Create Single Variant"
        :icon="$options.icons.Add"
        :to="{ name: 'product-item-variant', params: { variantId: '+' } }"
        feature="PRODUCT_VARIANT"
        permission="read"
      />
    </template>

    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="productVariants"
      :request-payload="{ query: $options.productVariants }"
      cache="productVariants"
      :params="{ productId: Number(productId) }"
      class="vp-h-full"
      ref="list"
      title="Product Variants"
      :search="false"
      @rowClick="
        $router.push({
          name: 'product-item-variant',
          params: { variantId: $event.id },
        })
      "
    >
      <template #empty>
        <VpErrorPage
          title="No Variants Found!"
          desc="Variable product needs atleast one variant to be listed on your store."
          label="Create Variants"
          :to="{ name: 'product-item-variant-bulk' }"
        />
      </template>
      <template #price="{ item }">
        <ProductPrice stack :price="item.price" :regular="item.regularPrice" />
      </template>
      <template #media="{ item }">
        <VpProductMedia :items="item.media" size="100x100" />
      </template>
      <template #status="{ item }">
        <VpStatus :value="item.status" />
      </template>
      <template #attributes="{ item }">
        <attributes :data="item.attributes" />
      </template>
      <template #stock="{ item }">
        <VpProductStock
          :is-out-of-stock="item.isOutOfStock"
          :value="item.stock"
        />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import Attributes from "../_Attributes.vue";
import { Add } from "icons/icons.js";
import ProductPrice from "components/src/product-price.vue";
import productVariants from "graph/productVariant/collection.gql";

export default {
  productVariants,
  icons: {
    Add,
  },
  props: {
    productId: [Number, String],
    variantsCount: Number,
  },
  components: {
    Attributes,
    ProductPrice,
  },

  computed: {
    columns() {
      return [
        { name: "media", fix: true, type: "string", width: "70px" },
        { name: "attributes", type: "string" },
        { name: "price", type: "number", fix: true },
        { name: "sku", label: "SKU", type: "string", fix: true },
        { name: "stock", label: "Stock", type: "string", fix: true },
        { name: "status", type: "string", fix: true },
      ];
    },
  },
};
</script>
