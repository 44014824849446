<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    :save="save"
    :get="get"
    class="vp-p-6"
    :archive="archive"
    width="400px"
    #default="{ state }"
    :track="$options.track"
    track-prefix="Category"
    collection-cache="productCategories"
    media-for="product_category"
    feature="PRODUCT_CATEGORY"
  >
    <VpField rules="required" label="Name" name="Name">
      <VpInput>
        <VpTextBox v-model="state.name" />
      </VpInput>
    </VpField>

    <VpField label="Image" optional>
      <!-- TODO: CATEGORY IMAGE SHOULD NOT BE AN ARRAY. FIX THIS IN API -->
      <VpImageEditor
        id="IMAGE"
        v-model="media"
        crop
        #default="{ value }"
        @beforeChange="onMediaDelete($event)"
        @delete="onMediaDelete($event)"
      >
        <VpMedia type="Image" size="96" :value="value" />
      </VpImageEditor>
    </VpField>

    <VpStatusInput v-model="state.status" />
  </VpVueForm>
</template>

<script>
import { createMedia, deleteMedia } from "utils/presets";

import del from "graph/productCategory/delete.gql";
import get from "graph/productCategory/get.gql";
import upsert from "graph/productCategory/upsert.gql";

export default {
  track: {
    id: "Id",
    name: "Name",
    status: "Status",
  },
  props: {
    id: [Number, String],
  },
  components: {},
  data() {
    return {
      media: {},
      fields: [
        "name",
        { name: "mediaId", value: [] },
        { name: "status", value: "active" },
      ],
    };
  },

  methods: {
    get(id) {
      return this.$query(get, {
        id: Number(id),
      }).then(({ data }) => {
        const { productCategory } = data;
        this.media = {
          url: productCategory.media[0]?.url,
          id: productCategory.media[0]?.id,
        };

        return {
          values: {
            ...productCategory,
            mediaId: productCategory.media[0]?.id,
          },
          res: data,
        };
      });
    },

    async save(id, data) {
      if (this.media.status == "CHANGE") {
        const uploadedMedia = await createMedia(
          "product_category",
          [this.media.url],
          [this.media.name]
        );
        this.media.status = null;
        data.mediaId = [uploadedMedia[0].id];
      } else if (this.media.status == "DELETE") {
        data.mediaId = [];
      }

      return this.$mutate(upsert, {
        input: {
          id: parseInt(id),
          ...data,
        },
      }).then(({ data }) => {
        this.$router.push({ name: "product-categories" });
        return data;
      });
    },

    onMediaDelete(data) {
      if (data.id) {
        deleteMedia([data.id]);
      }
    },

    archive(id, action) {
      return this.$mutate(del, {
        id: parseInt(id),
        action,
      }).then(({ data }) => data);
    },
  },
};
</script>
